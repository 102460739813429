import { Table, Form } from 'react-bootstrap';
import { NFTTableEntry } from './NFTTableEntry';
import WebNFT from '../../common/types/WebNFT';
import WebNFTHistory from '../../common/types/WebNFTHistory';
import '../../areas/forSaleProperties/forSaleProperties.css';

export interface OwnProps {
  nfts: WebNFT[],
  category: string,
  getNFTHistory: Function,
  isLoadingHistory: boolean,
  closeNFTHistory: Function,
  hasLoadingHistoryError: boolean,
  nftHistory: WebNFTHistory[],
  historyDGoodId: number,
  toggleImageModal: Function,
}

export const NFTDumbTableComponent = (props: OwnProps) => {

  const getSpecificColumnHeaders = () => {
    switch (props.category)
    {
      case "essential":
        return (
          <>
            <th>
              <Form.Label>Team</Form.Label>
            </th>
            <th>
              <Form.Label>Season</Form.Label>
            </th>
            <th>
              <Form.Label>Position</Form.Label>
            </th>
            <th>
              <Form.Label>Fan Points</Form.Label>
            </th>
            <th>
              <Form.Label>Model</Form.Label>
            </th>
            <th>
              <Form.Label>Variant</Form.Label>
            </th>
            <th>
              <Form.Label>Legit Type</Form.Label>
            </th>
          </>
        );
      case "memento":
        return (
          <>
            <th>
              <Form.Label>Team</Form.Label>
            </th>
            <th>
              <Form.Label>Season</Form.Label>
            </th>
            <th>
              <Form.Label>Position</Form.Label>
            </th>
            <th>
              <Form.Label>Fan Points</Form.Label>
            </th>
            <th>
              <Form.Label>Model</Form.Label>
            </th>
            <th>
              <Form.Label>Game Date</Form.Label>
            </th>
            <th>
              <Form.Label>Opponent</Form.Label>
            </th>
            <th>
              <Form.Label>Home Team</Form.Label>
            </th>
            <th>
              <Form.Label>Legit Type</Form.Label>
            </th>
          </>
        );
      case "blkexplorer":
        return (
          <>
            <th>
              <Form.Label>Series Name</Form.Label>
            </th>
            <th>
              <Form.Label>Rarity</Form.Label>
            </th>
            <th>
              <Form.Label>Description</Form.Label>
            </th>
          </>
        );
      case "spirithlwn":
        return (
          <>
            <th>
              <Form.Label>Rarity</Form.Label>
            </th>
          </>
        );
      case "structornmt":
        return (
          <>
            <th>
              <Form.Label>Rarity</Form.Label>
            </th>
            <th>
              <Form.Label>Building Type</Form.Label>
            </th>
          </>
        );
      case "landvehicle":
        return (
          <>
          </>
        );
      case "outdoordecor":
        return (
          <>
            <th>
              <Form.Label>Manufacturer</Form.Label>
            </th>
          </>
        );
      case "pass":
        return (
          <>
            <th>
              <Form.Label>Season</Form.Label>
            </th>
            <th>
              <Form.Label>Fan Points</Form.Label>
            </th>
            <th>
              <Form.Label>Model</Form.Label>
            </th>
            <th>
              <Form.Label>Legit Type</Form.Label>
            </th>
          </>
        );
    }
  }

  return (
    <div className="bg-light header-min-width">
      <Table>
        <thead>
          <tr className="form-aligns">
            <th>
              <Form.Label>Mint / Current / Max</Form.Label>
            </th>
            <th>
              <Form.Label>Name</Form.Label>
            </th>
            {getSpecificColumnHeaders()}
            <th>
              <Form.Label>Owner</Form.Label>
            </th>
            <th>
              <Form.Label></Form.Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.nfts && props.nfts.map((n) => 
            <NFTTableEntry 
              key={n.dGoodId} 
              nft={n} 
              category={props.category}
              getNFTHistory={props.getNFTHistory}
              isLoadingHistory={props.isLoadingHistory}
              closeNFTHistory={props.closeNFTHistory}
              hasLoadingHistoryError={props.hasLoadingHistoryError}
              nftHistory={props.nftHistory}
              historyDGoodId={props.historyDGoodId}
              toggleImageModal={props.toggleImageModal}
          />)}
        </tbody>
      </Table>
    </div>
  );
}
