import { CollatedStatsObject } from '../../common/types/CollatedStatsObject';
import WebCollection from '../../common/types/WebCollection';
import WebCity from '../../common/types/WebCity';
import { CollectionSquare } from '../CollectionSquareComponent/CollectionSquareComponent';

export interface OwnProps {
  entry: CollatedStatsObject,
  type: number,
  collection: WebCollection[],
  cities: WebCity[],
  useMint: boolean,
}

export const InfoTableEntry = (props: OwnProps) => {

  const getNameValue = () => {
    switch(props.type) {
      case 1:
      case 2:
      case 3:
        return (<td>{props.entry.name}</td>);
      case 4:
        return (<td>{props.entry.id != -1 && <CollectionSquare collection={props.collection[0]} displayRight={true} disableHover={false} />} {props.entry.name}</td>);
    }
  }

  let usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <tr>
      <td>{props.entry.id}</td>
      {getNameValue()}
      {props.type !== 1 && <td>{props.entry.id != -1 ? props.cities.filter((c) => c.cityId === props.entry.cityId)[0].name : ""}</td>}
      <td>{!props.useMint ? props.entry.totalProps : usdFormatter.format(props.entry.totalMint)}</td>
      <td>{props.entry.lockedProps}</td>
      <td>{!props.useMint ? props.entry.unlockedNonFSAProps : usdFormatter.format(props.entry.unlockedNonFSAMint)}</td>
      <td>{!props.useMint ? props.entry.unlockedFSAProps : usdFormatter.format(props.entry.unlockedFSAMint)}</td>
      <td>{!props.useMint ? props.entry.forSaleProps : usdFormatter.format(props.entry.forSaleMint)}</td>
      <td>{!props.useMint ? props.entry.ownedProps : usdFormatter.format(props.entry.ownedMint)}</td>
      <td>{!props.useMint ? props.entry.percentMinted.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : props.entry.percentMintMinted.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} %</td>
      <td>{!props.useMint ? props.entry.percentNonFSAMinted.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : props.entry.percentMintNonFSAMinted.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} %</td>
      <td>{props.entry.upxFloor === null ? "No Floor" : props.entry.upxFloor.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
      <td>{props.entry.usdFloor === null ? "No Floor" : props.entry.usdFloor.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
      <td>{props.entry.buildingCount}</td>
      <td>{props.entry.percentBuilt.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} %</td>
    </tr>
  );
}