import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Navbar, Nav, NavDropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import './header.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getUserProfile } from '../../store/actions/profileActions';
import { getCollections, getNeighborhoods, getStreets, getBlockchainStatus, getAnnouncements, getCities } from '../../store/actions/infoActions';
import { logout } from '../../store/actions/loginActions';

interface StateProps {
  authTokenSet: boolean,
  isProfileLoaded: boolean,
  isLoadingProfile: boolean,
  isLoadingCollections: boolean,
  isLoadingNeighborhoods: boolean,
  isLoadingCities: boolean,
  collectionsLoaded: boolean,
  neighborhoodsLoaded: boolean,
  citiesLoaded: boolean,
  hasLoadingInfoError: boolean,
  hasLoadingProfileError: boolean,
  errorMessage: string,
  isBlockchainDisabled: boolean,
  hasAnnouncement: boolean,
  announcement: string
};

interface DispatchProps {
  getUserProfile: () => void;
  getCollections: () => void;
  getNeighborhoods: () => void;
  getStreets: () => void;
  getCities: () => void;
  logout: () => void;
  getBlockchainStatus: () => void;
  getAnnouncements: () => void;
}

export type HeaderProps = StateProps & DispatchProps;

export const Header = (props: HeaderProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if(props.authTokenSet) { 
      props.getBlockchainStatus();
      props.getAnnouncements();

      
      if (!props.isProfileLoaded && !props.isLoadingProfile) {
        props.getUserProfile();
      }

      if (!props.collectionsLoaded && !props.isLoadingCollections) {
        props.getCollections();
      }

      if( !props.neighborhoodsLoaded && !props.isLoadingNeighborhoods) {
        props.getNeighborhoods();
      }

      if (!props.citiesLoaded && !props.isLoadingCities) {
        props.getCities();
      }
      
    }
  }, [navigate, props.isProfileLoaded, props.authTokenSet, props.collectionsLoaded, props.neighborhoodsLoaded])

  const navigateToLink = (link: string) => {
    navigate(link);
  }

  const performLogout = () => {
    props.logout();
    navigateToLink('./');
  }

  const renderLoggedInValues = () => {
    if (props.authTokenSet) {
      return (
        <Nav className="navbar-right">
          <NavDropdown title={"Profile"} className="nav-spacing">
            <NavDropdown.Item onClick={() => navigateToLink('./Profile')}>Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={performLogout}>Logout</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={"Tools"} className="nav-spacing">
            <NavDropdown.Item onClick={() => navigateToLink('./Tools/Optimizer')}>Optimizer</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateToLink('./Tools/Appraisal')}>Appraiser</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateToLink('./Tools/UserLookup')}>User Lookup</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateToLink('./Tools/Mapping')}>Mapping</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={"Data"} className="nav-spacing">
            <NavDropdown.Item onClick={() => navigateToLink('./Data/ForSaleProperties')}>For Sale Properties</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateToLink('./Data/UnmintedProperties')}>Unminted Properties</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateToLink('./Data/SaleHistory')}>Sale History</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateToLink('./Data/Statistics')}>Statistics</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateToLink('./Data/NFTs')}>Search NFTs</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigateToLink('./Data/Leaderboards')}>Leaderboards</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className="nav-spacing" onClick={() => navigateToLink('./Locations')}>Locations</Nav.Link>
        </Nav>
      );
    } else {
      return;
    }
  }

  const renderBlockchainError = () => {
    if (props.isBlockchainDisabled) {
      return (
        <OverlayTrigger
          key="bottom"
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-bottom`}>
              Blockchain Updating is Disabled! This data may be out of date.
            </Tooltip>
          }
        >
          <Navbar.Brand>
            <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle}/>
          </Navbar.Brand>
        </OverlayTrigger>
      );
    }
  }

  const renderAnnouncements = () => {
    if (props.hasAnnouncement) {
      return (
        <OverlayTrigger
          key="bottom"
          placement="bottom"
          overlay={
            <Tooltip id={`tooltip-bottom`} className="header-info-tooltip">
              <div dangerouslySetInnerHTML={{ __html: props.announcement }}></div>
            </Tooltip>
          }
        >
          <Navbar.Brand>
            <FontAwesomeIcon className="text-info" icon={faInfoCircle}/>
          </Navbar.Brand>
        </OverlayTrigger>
      );
    }
  }

  return (
    <Navbar className="navbar-dark bg-dark navbar-min-width" fixed="top">
      <Container fluid>
        <Nav className="nav-spacing">
          {renderLoggedInValues()}
        </Nav>
        <Nav className="navbar-right nav-spacing">
          {renderAnnouncements()}
          {renderBlockchainError()}
          <Navbar.Brand>
            Upland Optimizer
          </Navbar.Brand>
          <Navbar.Brand className="nav-spacing">
            <a href="https://discord.gg/hsrxQb7UQb" target="_blank">
              <FontAwesomeIcon icon={faDiscord}/>
            </a>
          </Navbar.Brand>
        </Nav>
      </Container>
    </Navbar>
  )
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    getUserProfile: () => dispatch(getUserProfile()),
    getCollections: () => dispatch(getCollections()),
    getNeighborhoods: () => dispatch(getNeighborhoods()),
    getCities: () => dispatch(getCities()),
    getStreets: () => dispatch(getStreets()),
    logout: () => dispatch(logout()),
    getBlockchainStatus: () => dispatch(getBlockchainStatus()),
    getAnnouncements: () => dispatch(getAnnouncements())
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.LoginState.authTokenSet,
    isProfileLoaded: state.ProfileState.isProfileLoaded,
    isLoadingProfile: state.ProfileState.isLoadingProfile,
    isLoadingCollections: state.InfoState.isLoadingCollections,
    isLoadingNeighborhoods: state.InfoState.isLoadingNeighborhoods,
    isLoadingCities: state.InfoState.isLoadingCities,
    collectionsLoaded: state.InfoState.collections.length > 0,
    neighborhoodsLoaded: state.InfoState.neighborhoods.length > 0,
    citiesLoaded: state.InfoState.cities.length > 0,
    hasLoadingInfoError: state.InfoState.hasError,
    hasLoadingProfileError: state.ProfileState.hasError,
    errorMessage: state.ProfileState.errorMessage + " " + state.InfoState.errorMessage,
    isBlockchainDisabled: state.InfoState.isBlockchainDisabled,
    hasAnnouncement: state.InfoState.hasAnnouncement,
    announcement: state.InfoState.announcement
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
