import CachedUnmintedProperty from '../../common/types/CachedUnmintedProperty';
import WebCollection from '../../common/types/WebCollection';
import { CollectionSquare } from '../CollectionSquareComponent/CollectionSquareComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faList } from '@fortawesome/free-solid-svg-icons';

export interface OwnProps {
  property: CachedUnmintedProperty,
  neighborhood: string,
  collections: WebCollection[],
}

export const UnmintedTableEntry = (props: OwnProps) => {

  return (
    <tr>
      <td>{props.property.address}</td>
      <td>{props.neighborhood}</td>
      <td>{props.property.size}</td>
      <td>{props.property.fsa ? 'FSA' : 'Non-FSA'}</td>
      <td>{props.property.mint.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
      <td>{props.collections.map((c) => <CollectionSquare key={c.id} collection={c} displayRight={false} disableHover={false} />)}</td>
      <td>
        <a href={"https://play.upland.me/?prop_id=" + props.property?.id} target="_blank" className="nft-table-icon-padding">
          <FontAwesomeIcon icon={faLink}/>
        </a>
      </td>
    </tr>
  );
}