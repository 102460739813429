import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Form, Col, Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from '../../components/LoadingComponent/Loading';
import { ErrorComponent } from '../../components/ErrorComponent/Error';
import '../forSaleProperties/forSaleProperties.css';
import WebCollection from '../../common/types/WebCollection';
import WebNeighborhood from '../../common/types/WebNeighborhood';
import WebCity from '../../common/types/WebCity';
import CachedUnmintedProperty from '../../common/types/CachedUnmintedProperty';
import WebForSaleFilters from '../../common/types/WebForSaleFilters';
import PostPropertiesUnmintedRequest from '../../common/types/messages/PostPropertiesUnmintedRequest';
import { postPropertiesUnminted, updateUnmintedForm } from '../../store/actions/propertyActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { fsas } from '../../common/enums/fsaEnum';
import { UnmintedPropertyTableComponent } from '../../components/UnmintedPropertyTableComponent/UnmintedPropertyTableComponent';
import { orderBys } from '../../common/enums/orderByEnum';
import { useAppContext } from  '../../common/app-context';
import { ApiService } from '../../common/api';
import { MultiSelect } from "react-multi-select-component"
import { LabelValuePair } from '../../common/types/LabelValuePair';
import { CollectionSquare } from '../../components/CollectionSquareComponent/CollectionSquareComponent';

interface StateProps {
  authTokenSet: boolean,

  isLoadingCollections: boolean,
  isLoadingNeighborhoods: boolean,
  isLoadingCities: boolean,
  collectionsLoaded: boolean,
  neighborhoodsLoaded: boolean,
  citiesLoaded: boolean,
  hasLoadingInfoError: boolean,
  infoErrorMessage: string,

  collections: WebCollection[],
  neighborhoods: WebNeighborhood[],
  cities: WebCity[],

  propertiesUnminted: CachedUnmintedProperty[];

  isLoadingUnmintedProperties: boolean;
  hasErrorUnmintedProp: boolean;
  errorUnmintedPropsMessage: string;

  unmintedFormState: WebForSaleFilters;
  unmintedSelectedFSA: string;
};

interface DispatchProps {
  postPropertiesUnminted: (request: PostPropertiesUnmintedRequest) => void;
  updateUnmintedForm: (filters: WebForSaleFilters, fsas: string) => void;
}

export type UnmintedPropertiesProps = DispatchProps & StateProps;

export const UnmintedProperties = (props: UnmintedPropertiesProps) => {
  const context = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.authTokenSet) {
      navigate("/Unauthorized");
    }

    if(!props.isLoadingUnmintedProperties) {
      searchUnmintedProperties();
    }
  }, [navigate, props.authTokenSet, props.unmintedFormState.page, props.unmintedFormState.cityId]);

  const downloadCSV = async () => {

    const apiService: ApiService = context.apiService;

    let filename = `${props.cities.filter((c) => c.cityId === props.unmintedFormState.cityId)[0].name.replace(/\s/g, '')}_unminted_properties.csv`;

    const csvData = (await apiService.postPropertiesUnminted$({filters: props.unmintedFormState, asCSV: true}).toPromise()).response.csvString;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } 
  }

  const handleEnterKeyPress = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      searchUnmintedProperties();
    }
  }

  const onUpdateFilters = (newFilters: WebForSaleFilters) => {
    props.updateUnmintedForm(newFilters, props.unmintedSelectedFSA);
  }

  const createLabelValueFromCollection = (collection: WebCollection) => {
    return {
      label: <><CollectionSquare key={collection.id} collection={collection} displayRight={false} disableHover={true} /> {collection.name}</>,
      value: "" + collection.id
    } as LabelValuePair
  }

  const createLabelValueFromNeighborhood = (neighborhood: WebNeighborhood) => {
    return {
      label: neighborhood.name,
      value: "" + neighborhood.id
    } as LabelValuePair
  }


  const onNeighborhoodsSelect = (selectedList: LabelValuePair[]) =>{
    props.updateUnmintedForm({
      ...props.unmintedFormState,
      neighborhoodIds: selectedList.map((c) => parseInt(c.value))
    }, props.unmintedSelectedFSA);
  }

  const renderNeighborhoodsSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Neighborhoods <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col xs={7}>
            <MultiSelect
              options={props.neighborhoods.filter(n => n.cityId === props.unmintedFormState.cityId).map((b) => createLabelValueFromNeighborhood(b))}
              value={props.neighborhoods.filter(n => props.unmintedFormState.neighborhoodIds.includes(n.id)).map((b) => createLabelValueFromNeighborhood(b))}
              onChange={onNeighborhoodsSelect}
              labelledBy="Select"
              className="multi-select"
            />
          </Col>
        </Row>
      </div>
    )
  }

  const customCollectionValueRenderer = (selected: any, _options: any) => {

    if (!selected.length) {
      return "Select...";
    }

    if (selected.length === _options.length) {
      return "All items are selected."
    }

    return selected.map((s: any) => props.collections.filter(c => c.id === parseInt(s.value))[0].name).join(", ");
  };

  const onCollectionSelect = (selectedList: LabelValuePair[]) =>{
    props.updateUnmintedForm({
      ...props.unmintedFormState,
      collectionIds: selectedList.map((c) => parseInt(c.value))
    }, props.unmintedSelectedFSA);
  }

  const renderCollectionsSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Collections <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col xs={7}>
            <MultiSelect
              options={props.collections.filter(c => c.cityId === props.unmintedFormState.cityId).map((b) => createLabelValueFromCollection(b))}
              value={props.collections.filter(c => props.unmintedFormState.collectionIds.includes(c.id)).map((b) => createLabelValueFromCollection(b))}
              onChange={onCollectionSelect}
              labelledBy="Select"
              className="multi-select"
              valueRenderer={customCollectionValueRenderer}
            />
          </Col>
        </Row>
      </div>
    )
  }

  const renderOrderBySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Order By <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.unmintedFormState.orderBy}
              onChange={e => {
                onUpdateFilters({
                  ...props.unmintedFormState,
                  orderBy: e.target.value
                });
            }}>
              {orderBys.filter((o) => o.key === 3 || o.key === 4).map((c) => <option key={c.key} value={c.value}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const onFSASelect = (fsaValue: string) =>{
    props.updateUnmintedForm({
      ...props.unmintedFormState,
      fsa: fsaValue === 'Any' ? undefined : fsaValue === 'FSA'
    }, fsaValue);
  }

  const renderFSASelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>FSA <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.unmintedSelectedFSA}
              onChange={e => {
                onFSASelect(e.target.value);
            }}>
              {fsas.map((c) => <option key={c.key} value={c.value}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderAscendingSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Direction <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.unmintedFormState.asc ? 'Ascending' : 'Descending'}
              onChange={e => {
                onUpdateFilters({
                  ...props.unmintedFormState,
                  asc: e.target.value === 'Ascending'
                });
            }}>
              <option key={"1"} value={'Ascending'}>Ascending</option>
              <option key={"2"} value={'Descending'}>Descending</option>
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderCitySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>City <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.unmintedFormState.cityId}
              onChange={e => {
                props.updateUnmintedForm({
                  ...props.unmintedFormState,
                  cityId: parseInt(e.target.value),
                  page: 1,
                  collectionIds: [],
                  neighborhoodIds: []
                }, props.unmintedSelectedFSA);
            }}>
              {props.cities.filter(x => x.isPrimaryCity).sort((aVal, bVal) => aVal.name > bVal.name ? 1 : -1).map((c) => <option key={c.cityId} value={c.cityId}>{c.name}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderAddressBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Address</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.unmintedFormState.address}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.unmintedFormState,
                  address: e.target.value
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const searchUnmintedProperties= () => {
    props.postPropertiesUnminted({filters: props.unmintedFormState, asCSV: false});
  }

  const renderColumnOne = () => {
    if (props.isLoadingCollections || props.isLoadingNeighborhoods || props.isLoadingUnmintedProperties) {
      return (
        <div>
        <Row><h2>Unminted Properties</h2></Row>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      );
    } else {
      return (
        <div>
          <Row><h2>Unminted Properties</h2></Row>
          <Row>
            <Button variant="dark" onClick={searchUnmintedProperties} disabled={props.isLoadingUnmintedProperties}>Search Properties</Button>
            <Button className="for-sale-download-csv" variant="dark" onClick={downloadCSV} disabled={props.isLoadingUnmintedProperties}>Download CSV <FontAwesomeIcon icon={faDownload} /></Button>
          </Row>
        </div>
      );
    }
  }

  const renderColumnTwo = () => {
    return (
      <div>
        {renderCitySelect()}
        {renderAddressBox()}
        {renderFSASelect()}
      </div>
    );
  }

  const renderColumnThree = () => {
    return (
      <div>
        {renderOrderBySelect()}
        {renderAscendingSelect()}
      </div>
    );
  }

  const renderColumnFour = () => {
    return (
      <div>
        {renderCollectionsSelect()}
        {renderNeighborhoodsSelect()}
      </div>
    );
  }

  const renderForSalePropertiesHeader = () => {
    return (
      <Container fluid={true} className="bg-secondary text-light">
        <Row className="profile-header header-min-width">
          <Col className="input-form-column-one input-form-column header-info-column-width-250">
            {renderColumnOne()}
          </Col>
          <Col className="input-form-column header-info-column-width-350">
            {renderColumnTwo()}
          </Col>
          <Col className="input-form-column header-info-column-width-350">
            {renderColumnThree()}
          </Col>
          <Col className="header-info-column-width-450-right">
            {renderColumnFour()}
          </Col>
        </Row>
      </Container>
    );
  }

  const renderForSalePropertiesBody = () => {
    return (
      <div>
        <UnmintedPropertyTableComponent 
          properties={props.propertiesUnminted} 
          neighborhoods={props.neighborhoods} 
          collections={props.collections} />
      </div>
    );
  }

  const onPageClick = (direction: number) => {
    if (direction === 0 && props.unmintedFormState.page > 1) {
      onUpdateFilters({
        ...props.unmintedFormState,
        page: props.unmintedFormState.page - 1
      })
    } else if (direction === 1 && props.unmintedFormState.page) {
      onUpdateFilters({
        ...props.unmintedFormState,
        page: props.unmintedFormState.page + 1
      })
    }
  }

  const updatePageNumber = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    let parsedNum = parseInt(changeEvent.currentTarget.value);

    if (isNaN(parsedNum)) {
      parsedNum = 0;
    }
    onUpdateFilters({
      ...props.unmintedFormState,
      page: parsedNum
    });
  }

  const renderForSaleProperties = () => {
    if (props.isLoadingCollections || props.isLoadingNeighborhoods) {
      return (
        <LoadingComponent />
      );
    } else if (props.hasLoadingInfoError) {
      return (
        <ErrorComponent errorMessage={props.infoErrorMessage} />
      );
    } else {
      return (
        <div>
          {renderForSalePropertiesHeader()}
          {renderForSalePropertiesBody()}
        </div>
      );
    }
  }

  return (
    <Container fluid={true} className="bg-secondary text-light">
      {renderForSaleProperties()}
      <div className="page-selector-class">
        <Row>
          <Button className="page-selector-left" onClick={() => onPageClick(0)}><FontAwesomeIcon icon={faArrowLeft} /></Button>
          <Col className="page-selector-center">
            <Row>
              <Col className="page-selector-center-left"><Form.Label>Page</Form.Label></Col>
              <Col className="page-selector-center-right"><Form.Control size="sm" placeholder="Page" type="text" value={props.unmintedFormState.page} onChange={updatePageNumber} /></Col>
            </Row>
          </Col>
          <Button className="page-selector-right"onClick={() => onPageClick(1)}><FontAwesomeIcon icon={faArrowRight} /></Button>
        </Row>
      </div>
    </Container>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    postPropertiesUnminted: (request: PostPropertiesUnmintedRequest) => dispatch(postPropertiesUnminted(request)),
    updateUnmintedForm: (filters: WebForSaleFilters, fsas: string) => dispatch(updateUnmintedForm(filters, fsas))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.LoginState.authTokenSet,
    
    isLoadingCollections: state.InfoState.isLoadingCollections,
    isLoadingNeighborhoods: state.InfoState.isLoadingNeighborhoods,
    isLoadingCities: state.InfoState.isLoadingCities,
    collectionsLoaded: state.InfoState.collections.length > 0,
    neighborhoodsLoaded: state.InfoState.neighborhoods.length > 0,
    citiesLoaded: state.InfoState.cities.length > 0,
    hasLoadingInfoError: state.InfoState.hasError,
    infoErrorMessage: state.InfoState.errorMessage,

    collections: state.InfoState.collections,
    neighborhoods: state.InfoState.neighborhoods,
    cities: state.InfoState.cities,

    propertiesUnminted: state.PropertyState.propertiesUnminted,

    isLoadingUnmintedProperties: state.PropertyState.isLoadingUnmintedProperties,
    hasErrorUnmintedProp: state.PropertyState.hasErrorUnmintedProp,
    errorUnmintedPropsMessage: state.PropertyState.errorUnmintedPropsMessage,

    unmintedFormState: state.PropertyState.unmintedFormState,
    unmintedSelectedFSA: state.PropertyState.unmintedSelectedFSA
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(UnmintedProperties);