import { KeyValuePair } from '../types/KeyValuePair';

export const buildings: KeyValuePair[] = [
  { key: 0, value: "Apartment Building" },
  { key: 1, value: "Luxury Modern House" },
  { key: 2, value: "Luxury Ranch House" },
  { key: 3, value: "Ranch House" },
  { key: 4, value: "Small Town House Flat Roof" },
  { key: 5, value: "Small Town House" },
  { key: 6, value: "Town House" },
  { key: 7, value: "Mavs Perch, Manhattan" },
  { key: 8, value: "CMavs KCAD, Kansas City" },
  { key: 9, value: "Cornerstone, Rutherford" },
  { key: 10, value: "Manty Mansion, Nashville" },
  { key: 11, value: "Contemporary House, Staten Island" },
  { key: 12, value: "Williamsburg Townhouse, Brooklyn" },
  { key: 13, value: "Manty Cottage, Bronx" },
  { key: 14, value: "Super Luxury Ranch House, New Orleans" },
  { key: 15, value: "Ferry Building, San Francisco" },
  { key: 16, value: "Coit Tower, San Francisco" },
  { key: 17, value: "Football Stadium, Chicago" },
  { key: 18, value: "Football Stadium, Cleveland" },
  { key: 19, value: "SoFi Stadium, Los Angeles" },
  { key: 20, value: "2610, San Francisco" },
  { key: 21, value: "10335, Chicago" },
  { key: 22, value: "1001 SF, San Francisco" },
  { key: 23, value: "Metaverse Motors Factory, Santa Clara" },
  { key: 24, value: "Trump Tower, Manhattan" },
  { key: 25, value: "Soldier Field Stadium, Chicago" },
  { key: 26, value: "NYSE, Manhattan" },
  { key: 27, value: "Battery Weed, Staten Island" },
  { key: 28, value: "Flatiron Building, Manhattan" },
  { key: 29, value: "Nissan Stadium, Nashville" },
  { key: 30, value: "MetaMotors Show Room, Santa Clara" },
  { key: 31, value: "Levi's Stadium, Santa Clara" },
  { key: 32, value: "Holy Name Cathedral, Chicago" },
  { key: 33, value: "Lillicorp Tower, San Francisco" },
  { key: 34, value: "Victorian House, Santa Clara" },
  { key: 35, value: "BigNick Midcentury Ranch House, Fresno" },
  { key: 36, value: "SERA Design Postmodern, Oakland" },
  { key: 37, value: "Craftsman House, Bakersfield" },
  { key: 38, value: "Residential Tower, Cleveland" },
  { key: 39, value: "Chic Hollywood Hills, Los Angeles" },
  { key: 40, value: "Assassins Courtyard, Chicago" },
  { key: 41, value: "Small Factory I"},
  { key: 42, value: "Palácio do Samba, Rio de Janeiro"},
  { key: 43, value: "Micro House"},
  { key: 44, value: "Nissan Stadium, Nashville"},
  { key: 45, value: "Metlife Stadium, Rutherford"},
  { key: 46, value: "Ford Field Stadium, Detroit"},
  { key: 47, value: "Arrowhead Stadium, Kansas City"},
  { key: 48, value: "FirstEnergy NFL Stadium, Cleveland"},
  { key: 49, value: "Allegiant Stadium, Las Vegas"},
  { key: 50, value: "Caesars Superdome, New Orleans"},
  { key: 51, value: "FC Porto Stadium, Porto"},
  { key: 52, value: "Medium Factory I"},
  { key: 53, value: "Cloud Gate, Chicago"},
  { key: 54, value: "Small Showroom I"},
  { key: 55, value: "Large Factory I"},
  { key: 56, value: "Medium Showroom I"},
  { key: 57, value: "Large Showroom I"},
];
