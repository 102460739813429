import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, Container, Row, Col, Image} from 'react-bootstrap'
import PostRegisterRequest from '../../common/types/messages/PostRegisterRequest';
import PostLoginRequest from '../../common/types/messages/PostLoginRequest';
import PostResetPasswordRequest from '../../common/types/messages/PostResetPasswordRequest';
import { registerUser, updateRegisterForm, login, resetPassword, ClearAccessCode } from '../../store/actions/loginActions';
import "bootstrap/dist/css/bootstrap.min.css";
import "./login.css";
import { VerifyError } from '../../components/VerifyErrorComponent/VerifyError';
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from '../../components/LoadingComponent/Loading';

interface StateProps {
  authTokenSet: boolean;
  uplandUsername: string;
  isLoading: boolean;
  verificationNeeded: boolean;
  accessCode?: string;
  hasError: boolean;
  errorMessage: string;
};

interface DispatchProps {
  registerUser: (payload: PostRegisterRequest) => void;
  login: (payload: PostLoginRequest) => void;
  resetPassword: (payload: PostResetPasswordRequest) => void;
  updateRegisterForm: (payload: string) => void;
  clearAccessCode: () => void;
}


export type LoginProps = DispatchProps & StateProps;

export const Login = (props: LoginProps) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (props.authTokenSet) {
      navigate("/Profile");
    }
  })

  const [pageView, setPageView] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formValidMessage, setFormValidMessage] = useState('');

  const LoginMenuButtonAction = () => {
    setFormValidMessage('');
    setPageView("Login");
  }

  const RegisterMenuButtonAction = () => {
    setFormValidMessage('');
    setPageView("Register");
  }

  const PasswordResetMenuButtonAction = () => {
    setFormValidMessage('');
    setPageView("PasswordReset");
  }

  const AboutMenuButtonAction = () => {
    setFormValidMessage('');
    setPageView("About");
  }

  const callRegister = () => {
    if (validatePasswords()) {
      props.registerUser({username: props.uplandUsername, password: password} as PostRegisterRequest);
    }
  }

  const callLogin = () => {
    props.login({username: props.uplandUsername, password: password} as PostLoginRequest);
  }

  const callResetPassword = () => {
    if (validatePasswords()) {
      props.resetPassword({username: props.uplandUsername, password: password} as PostResetPasswordRequest);
    }
  }

  const validatePasswords = () => {
    if (password !== '' && confirmPassword !== '') {
      if (password.length < 8) {
        setFormValidMessage('Passwords must be at least 8 characters');
        return false;
      }

      if (password === confirmPassword) {
        setFormValidMessage('');
        return true;
      } else {
        setFormValidMessage('Passwords must match');
      }
    } else {
      setFormValidMessage('Passwords must not be empty');
    }

    return false;
  }

  const updateUsernameInState = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    props.updateRegisterForm(changeEvent.currentTarget.value);
  } 

  const renderGreenBox = (message: string) => {
    return (
      <div className="login-green-box-class bg-success shadow-sm">
      {message}
      </div>
    );
  }

  const handleEnterKeyPress = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      if (pageView === "Register") {
        callRegister();
      } else if (pageView === "Login") {
        callLogin();
      }
    }
  }

  const updatePassword = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(changeEvent.currentTarget.value);
  } 

  const updateConfirmPassword = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(changeEvent.currentTarget.value);
  } 

  const renderPageView = () => {
    if (pageView === "Login") {
      return renderLoginPageView();
    } else if (pageView === "PasswordReset") {
      return renderResetPasswordPageView();
    } else if (pageView === "Register") {
      return renderRegisterPageView();
    } else {
      return renderAboutPageView();
    }
  }

  const clearAccessCode = () => {
    props.clearAccessCode();
  }

  const renderRegisterPageView = () => {
    if (props.verificationNeeded) {
      return (
        <>
          <Row>
            <Col xs></Col>
            <Col>
              <h1 className="text-center">Register</h1>
              {renderOTPInstructions()}
            </Col>
            <Col xs></Col>
          </Row>
          <Row>
          <Col xs></Col>
            <Col xs={4}>
              <Button className="login-button-width float-end" variant="dark" disabled={props.isLoading} onClick={clearAccessCode}>Clear</Button>
            </Col>
            <Col xs></Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row>
        <Col xs></Col>
          <Col>
            <h1 className="text-center">Register</h1>
            <p className="text-center">Enter Your Upland Username below, Click Register, and Follow the Instructions.</p>
            <Form onSubmit={e => {e.preventDefault()}}>
              <Form.Group className="register-margin">
                <Form.Label>Upland Username</Form.Label>
                <Form.Control type="text" value={props.uplandUsername} onKeyPress={handleEnterKeyPress} onChange={updateUsernameInState}/>
              </Form.Group>
              {formValidMessage !== '' && <VerifyError message={formValidMessage}/>}
              {props.hasError && <VerifyError message={props.errorMessage}/>}
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" onChange={updatePassword} placeholder={"DO NOT USE YOUR UPLAND PASSWORD!"}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" onKeyPress={handleEnterKeyPress} onChange={updateConfirmPassword} placeholder={"DO NOT USE YOUR UPLAND PASSWORD!"}/>
              </Form.Group>
              <Form.Group>
                <Button className="login-button-width" variant="dark" disabled={props.isLoading} onClick={callRegister}>Register</Button>
              </Form.Group>
            </Form>
          </Col>
          <Col xs></Col>
        </Row>
      );
    }
  }

  const renderLoginPageView = () => {
    return (
      <Row>
      <Col xs></Col>
        <Col>
          <h1 className="text-center">Login</h1>
          <Form onSubmit={e => {e.preventDefault()}}>
            <Form.Group className="register-margin">
              <Form.Label>Upland Username</Form.Label>
              <Form.Control type="text" value={props.uplandUsername} onKeyPress={handleEnterKeyPress} onChange={updateUsernameInState}/>
            </Form.Group>
            {formValidMessage !== '' && <VerifyError message={formValidMessage}/>}
            {props.hasError && <VerifyError message={props.errorMessage}/>}
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" onChange={updatePassword} placeholder={"DO NOT USE YOUR UPLAND PASSWORD!"}/>
            </Form.Group>
            <Form.Group>
              <Button className="login-button-width" variant="dark" disabled={props.isLoading} onClick={callLogin}>Login</Button>
            </Form.Group>
          </Form>
        </Col>
        <Col xs></Col>
      </Row>
    );
  }

  const renderOTPInstructions = () => {
    if (props.verificationNeeded) {
      return(
        <>
          <h3 className="text-center">Please verify you are {props.uplandUsername}</h3>
          <div><b>{renderGreenBox("" + props.accessCode)}</b></div>
          <ol>
            <li>Open Upland, and login to the game</li>
            <li>Open the Quick Menu, and click on Settings</li>
            <li>Click on Third-Party Applications</li>
            <li>Click Connect New App</li>
            <li>Enter the above access code</li>
            <li>Click Submit</li>
            <li>If success return here and login</li>
            <li>If not reach out on Discord</li>
          </ol>
        </>
      );
    } else {
      return(
        <>
          <LoadingComponent />
        </>
      );
    }
  }

  const renderResetPasswordPageView = () => {
    if (props.verificationNeeded) {
      return (
        <>
          <Row>
            <Col xs></Col>
            <Col>
              <h1 className="text-center">Reset Password</h1>
              {renderOTPInstructions()}
            </Col>
            <Col xs></Col>
          </Row>
          <Row>
          <Col xs></Col>
            <Col>
              <Button className="login-button-width float-end" variant="dark" disabled={props.isLoading} onClick={clearAccessCode}>Clear</Button>
            </Col>
            <Col xs></Col>
          </Row>
        </>
      );
    } else {
      return (
        <Row>
        <Col xs></Col>
          <Col>
            <h1 className="text-center">Reset Password</h1>
            <Form onSubmit={e => {e.preventDefault()}}>
              <Form.Group className="register-margin">
                <Form.Label>Upland Username</Form.Label>
                <Form.Control type="text" value={props.uplandUsername} onKeyPress={handleEnterKeyPress} onChange={updateUsernameInState}/>
              </Form.Group>
              {formValidMessage !== '' && <VerifyError message={formValidMessage}/>}
              {props.hasError && <VerifyError message={props.errorMessage}/>}
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" onChange={updatePassword} placeholder={"DO NOT USE YOUR UPLAND PASSWORD!"}/>
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" onKeyPress={handleEnterKeyPress} onChange={updateConfirmPassword} placeholder={"DO NOT USE YOUR UPLAND PASSWORD!"}/>
              </Form.Group>
              <Form.Group>
                <Button className="login-button-width" variant="dark" disabled={props.isLoading} onClick={callResetPassword}>Reset</Button>
              </Form.Group>
            </Form>
          </Col>
          <Col xs></Col>
        </Row>
      );
    }
  }

  const renderAboutPageView = () => {
    return (
      <>
      <Row>
        <Col xs></Col>
        <Col xs={8}>
          <Row>
            <Image className="m-auto login-image" src="/optimizerIcon.png"/>
          </Row>
        </Col>
        <Col xs></Col>
      </Row>
      <Row>
        <Col xs></Col>
        <Col className="about-min-column-width" xs={1}>
          <h4>Creator</h4>
        </Col>
        <Col xs = {6}>
          <p>
            Grombrindal, also known as Hornbrod in game. He has been in Upland since October of 2020.
          </p>
        </Col>
        <Col xs></Col>
      </Row>
      <Row>
        <Col xs></Col>
        <Col className="about-min-column-width" xs={1}>
          <h4>Creation</h4>
        </Col>
        <Col xs = {6}>
          <p>
           The Upland Optimizer a collection of tools, and data for Upland.me. It is a labor of love, with new features being added at the whim of Grombrindal. Below are some of the existing features.
          </p>
          <ul>
            <li>Property Collection Optimizer</li>
            <li>Mapping Tool</li>
            <li>User Lookup</li>
            <li>Property Search</li>
            <li>Sale History</li>
            <li>Appraisal</li>
          </ul>
        </Col>
        <Col xs></Col>
      </Row>
      <Row>
        <Col xs></Col>
        <Col className="about-min-column-width" xs={1}>
          <h4>History</h4>
        </Col>
        <Col xs = {6}>
          <p>
           The Upland Optimizer started as a Discord bot is October of 2021. Over time the features were expanded, and eventually a website was developed and launched in Feburary of 2022. Since this is a one man band the Discord bot had to be put out to pasture in September of 2022 so the focus could be put into the website.
          </p>
        </Col>
        <Col xs></Col>
      </Row>
      <Row>
        <Col xs></Col>
        <Col className="about-min-column-width" xs={1}>
          <h4>Help</h4>
        </Col>
        <Col xs = {6}>
          <p>
            If you get lost, or have questions on the optimizer, try asking in the discord channel by using the link in the top right hand corner. If that doesn't work you can contact Grombrindal driectly in game by opening a chat with Hornbrod.
          </p>
        </Col>
        <Col xs></Col>
      </Row>
      </>
    );
  }

  return (
    <Container fluid={true} className="login-page-height bg-secondary text-light">
      <Row>
        <Col xs></Col>
        <Col xs={10}>
          <h1 className="text-center">Upland Optimizer</h1>
        </Col>
        <Col xs></Col>
      </Row>
      <Row>
        <Col xs></Col>
        <Col xs={10}>
          <Form.Group className="button-placing">
            <Button className="login-button-width" variant="dark" onClick={LoginMenuButtonAction}>Login</Button>
            <Button className="float-end login-button-width" variant="dark" onClick={RegisterMenuButtonAction}>Register</Button>
          </Form.Group>
          <Form.Group className="button-placing">
            <Button className="login-button-width" variant="dark" onClick={PasswordResetMenuButtonAction}>Reset Password</Button>
            <Button className="float-end login-button-width" variant="dark" onClick={AboutMenuButtonAction}>About</Button>
          </Form.Group>
        </Col>
        <Col xs></Col>
      </Row>
      {renderPageView()}
    </Container>
  )
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.LoginState.authTokenSet,
    uplandUsername: state.LoginState.uplandUsername,
    isLoading: state.LoginState.isLoading,
    verificationNeeded: state.LoginState.verificationNeeded,
    accessCode: state.LoginState.accessCode,
    hasError: state.LoginState.hasError,
    errorMessage: state.LoginState.errorMessage,
  }
  return stateProps;
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    registerUser: (payload: PostRegisterRequest) => dispatch(registerUser(payload)),
    login: (payload: PostLoginRequest) => dispatch(login(payload)),
    resetPassword: (payload: PostResetPasswordRequest) => dispatch(resetPassword(payload)),
    updateRegisterForm: (payload: string) => dispatch(updateRegisterForm(payload)),
    clearAccessCode: () => dispatch(ClearAccessCode())
  }

  return dispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
