import { Reducer } from 'redux';
import { LeaderboardsState } from '../states/leaderboardsState';
import {
  LeaderboardsActionTypes,
  PostLeaderboardsActionType,
  PostLeaderboardsCompletedActionType,
  PostLeaderboardsFailedActionType,
  UpdateLeaderboardsFormActionType
} from '../actions/leaderboardActions';
import LeaderboardsFormFields from '../../common/types/LeaderboardsFormFields';

export type LeaderboardsActions = 
  PostLeaderboardsActionType
  | PostLeaderboardsCompletedActionType
  | PostLeaderboardsFailedActionType
  | UpdateLeaderboardsFormActionType;

const initialLeaderboardsForm: LeaderboardsFormFields = {
  Type: 1,
  TimeType: "Hour",
  TimeValue: 1,
  TeamName: "All",
  NFTType: "All",

  nameFilter: "",
  additionalInfoFilter: "",
  pageSize: 100,
  page: 1
}

const initialState: LeaderboardsState = {
  isLoadingLeaderboards: false,
  hasError: false,
  errorMessage: '',

  leaderboard: [],

  leaderboardsForm: initialLeaderboardsForm
}

export const LeaderboardsReducer: Reducer<LeaderboardsState> = (state = initialState, action: LeaderboardsActions): LeaderboardsState => {
  switch(action.type) {
    case LeaderboardsActionTypes.PostLeaderboards:
      return {
        ...state,
        isLoadingLeaderboards: true,
        hasError: false,
        errorMessage: '',

        leaderboard: []
      };
    case LeaderboardsActionTypes.PostLeaderboardsCompleted:
      return {
        ...state,
        isLoadingLeaderboards: false,
        hasError: false,
        errorMessage: '',

        leaderboard: action.payload!.response.leaderboard,
      };
    case LeaderboardsActionTypes.PostLeaderboardsFailed:
      return {
        ...state,
        isLoadingLeaderboards: false,
        hasError: true,
        errorMessage: action.payload!.error as any,

        leaderboard: []   
      };
    case LeaderboardsActionTypes.UpdateLeaderboardsForm:
      return {
        ...state,
        leaderboardsForm: action.payload!.formValues
      };
    default:
      return state;
  }
}

export default LeaderboardsReducer;