import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Form, Col, Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import './searchNFTs.css';
import { KeyValuePair } from '../../common/types/KeyValuePair';
import WebNFT from '../../common/types/WebNFT';
import WebNFTFilters from '../../common/types/WebNFTFilters';
import WebNFTHistory from '../../common/types/WebNFTHistory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { nftCategoriesEnum } from '../../common/enums/nftCategoriesEnum';
import { trueFalseNullEnum } from '../../common/enums/trueFalseNullEnum';
import { orderBysNFT } from '../../common/enums/orderByNFTEnum';
import { useAppContext } from  '../../common/app-context';
import { ApiService } from '../../common/api';
import PostSearchNFTRequest from '../../common/types/messages/PostSearchNFTRequest';
import { getNFTHistory, postSearchNFT, updateNFTForm, updateNFTFormCategory, closeNFTHistory, toggleImageModal } from '../../store/actions/nftActions';
import { NFTDumbTableComponent } from '../../components/NFTTableComponent/NFTDumbTableComponent';
import ImageModalComponent from '../../components/ImageModalComponent/ImageModalComponent';

interface StateProps {
  authTokenSet: boolean,

  isLoadingNFTs: boolean;
  hasError: boolean;
  errorMessage: string;
  
  nfts: WebNFT[];

  isLoadingHistory: boolean;
  nftHistory: WebNFTHistory[];
  hasLoadingHistoryError: boolean;
  loadingHistoryError: string;
  historyDGoodId: number;

  formValues: WebNFTFilters;
};

interface DispatchProps {
  postSearchNFTs: (request: PostSearchNFTRequest) => void;
  getNFTHistory: (request: number) => void;
  updateNFTForm: (formValues: WebNFTFilters) => void;
  updateNFTFormCategory: (category: string) => void;
  closeNFTHistory: () => void;
  toggleImageModal: (url: string, name: string) => void;
}

export type SearchNFTsProps = DispatchProps & StateProps;

export const SearchNFTs = (props: SearchNFTsProps) => {
  const context = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.authTokenSet) {
      navigate("/Unauthorized");
    }
  }, [navigate, props.authTokenSet]);

  const downloadCSV = async () => {

    const apiService: ApiService = context.apiService;

    let filename = `${props.formValues.category}_nfts.csv`;

    const csvData = (await apiService.postSearchNFTs$({filters: {
        ...props.formValues,
        noPaging: true
      }, 
      asCSV: true}).toPromise()).response.csvString;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } 
  }

  const handleEnterKeyPress = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      searchForNFTs();
    }
  }

  const onUpdateFilters = (newFilters: WebNFTFilters) => {
    props.updateNFTForm(newFilters);
  }

  const renderOrderBySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Order By <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.formValues.sortBy}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  sortBy: e.target.value
                });
            }}>
              {orderBysNFT
                .filter(b => props.formValues.category === "essential" || props.formValues.category === "memento" || b.value !== "Fan Points")
                .map((c) => <option key={c.key} value={c.key}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderAscendingSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Direction <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.formValues.sortDescending ? 'Descending' : 'Ascending'}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  sortDescending: e.target.value === 'Descending'
                });
            }}>
              <option key={'Ascending'} value={'Ascending'}>Ascending</option>
              <option key={'Descending'} value={'Descending'}>Descending</option>
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderCategorySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Category <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.formValues.category}
              onChange={e => {
                props.updateNFTFormCategory(e.target.value);
              }}>
              {nftCategoriesEnum.filter(n => n.value !== "Structure").map((c: KeyValuePair) => <option key={c.key} value={c.key}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderVariantBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Variant <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.formValues.filters.isVariantFilter}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    isVariantFilter: parseInt(e.target.value)
                  },
                });
              }}>
              {trueFalseNullEnum.map((c: KeyValuePair) => <option key={c.key} value={c.key}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderNameBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Name</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.name}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    name: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderRarityBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Rarity</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.rarity}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    rarity: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderTeamBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Team</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.team}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    team: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderManufacturerName = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Maker</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.manufacturerName}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    manufacturerName: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderSeriesName = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Series</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.seriesName}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    seriesName: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderBuildingType = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Building</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.buildingType}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    buildingType: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderPosition = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Position</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.position}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    position: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderYear = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Season</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.year}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    year: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderModelType = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Model</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.modelType}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    modelType: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderOpponent = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Opponent</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.opponent}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    opponent: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderHomeTeam = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Home</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.homeTeam}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    homeTeam: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderLegitType = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Legit Type</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.legitType}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    legitType: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderOwnerBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Owner</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.formValues.filters.owner}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  filters: {
                    ...props.formValues.filters,
                    owner: e.target.value
                  }
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderIncludeBurnedBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Burned <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.formValues.includeBurnedFilter}
              onChange={e => {
                onUpdateFilters({
                  ...props.formValues,
                  includeBurnedFilter: parseInt(e.target.value)
                });
              }}>
              {trueFalseNullEnum.map((c: KeyValuePair) => <option key={c.key} value={c.key}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }


  const searchForNFTs= () => {
    props.postSearchNFTs({filters: props.formValues, asCSV: false});
  }

  const renderColumnOne = () => {
    if (props.isLoadingNFTs) {
      return (
      <div>
        <Row><h2>Search NFTs</h2></Row>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      );
    } else {
      return (
        <div>
          <Row><h2>Search NFTs</h2></Row>
          <Row>
            <Button variant="dark" onClick={searchForNFTs} disabled={props.isLoadingNFTs}>Search NFTs</Button>
            <Button className="for-sale-download-csv" variant="dark" onClick={downloadCSV} disabled={props.isLoadingNFTs}>Download CSV <FontAwesomeIcon icon={faDownload} /></Button>
          </Row>
        </div>
      );
    }
  }

  const renderColumnTwo = () => {
    return (
      <div>
        {renderCategorySelect()}
        {renderNameBox()}
        {renderOwnerBox()}
      </div>
    );
  }

  const renderColumnThree = () => {
    return (
      <div>
        {(props.formValues.category === "essential" || props.formValues.category === "memento" || props.formValues.category === "pass") && renderYear()}
        {(props.formValues.category === "essential" || props.formValues.category === "memento") && renderTeamBox()}
        {(props.formValues.category === "blkexplorer" || props.formValues.category === 'structornmt' || props.formValues.category === 'spirithlwn') && renderRarityBox()}
        {props.formValues.category === "blkexplorer" && renderSeriesName()}
        {props.formValues.category === "structornmt" && renderBuildingType()}
        {props.formValues.category === "outdoordecor" && renderManufacturerName()}
        {(props.formValues.category === "essential" || props.formValues.category === "memento") && renderPosition()}
      </div>
    );
  }

  const renderColumnFour = () => {
    return (
      <div>
        {(props.formValues.category === "essential" || props.formValues.category === "memento" || props.formValues.category === "pass") && renderModelType()}
        {(props.formValues.category === "essential" || props.formValues.category === "memento" || props.formValues.category === "pass") && renderLegitType()}
        {props.formValues.category === "essential" && renderVariantBox()}
      </div>
    );
  }

  const renderColumnFive = () => {
    return (
      <div>
        {renderOrderBySelect()}
        {renderAscendingSelect()}
        {renderIncludeBurnedBox()}
      </div>
    );
  }

  const renderSearchNFTsHeader = () => {
    return (
      <Container fluid={true} className="bg-secondary text-light">
        <Row className="profile-header header-min-width">
          <Col className="input-form-column-one input-form-column header-info-column-width-250">
            {renderColumnOne()}
          </Col>
          <Col className="input-form-column header-info-column-width-300">
            {renderColumnTwo()}
          </Col>
          <Col className="input-form-column header-info-column-width-250">
            {renderColumnThree()}
          </Col>
          <Col className="input-form-column header-info-column-width-300">
            {renderColumnFour()}
          </Col>
          <Col className="input-form-column header-info-column-width-300">
            {renderColumnFive()}
          </Col>
        </Row>
      </Container>
    );
  }

  const renderSearchNFTsBody = () => {
    return (
      <div className="table-max-height">
        <NFTDumbTableComponent 
          nfts={props.nfts.slice((props.formValues.page - 1) * 100, (props.formValues.page * 100))} 
          category={props.formValues.category}
          getNFTHistory={props.getNFTHistory}
          isLoadingHistory={props.isLoadingHistory}
          closeNFTHistory={props.closeNFTHistory}
          hasLoadingHistoryError={props.hasLoadingHistoryError}
          nftHistory={props.nftHistory}
          historyDGoodId={props.historyDGoodId}
          toggleImageModal={props.toggleImageModal}
          />
      </div>
    );
  }

  const onPageClick = (direction: number) => {
    if (direction === 0 && props.formValues.page > 1) {
      onUpdateFilters({
        ...props.formValues,
        page: props.formValues.page - 1
      })
    } else if (direction === 1 && props.formValues.page) {
      onUpdateFilters({
        ...props.formValues,
        page: props.formValues.page + 1
      })
    }
  }

  const updatePageNumber = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    let parsedNum = parseInt(changeEvent.currentTarget.value);

    if (isNaN(parsedNum)) {
      parsedNum = 0;
    }
    onUpdateFilters({
      ...props.formValues,
      page: parsedNum
    });
  }

  const renderSearchNFTs = () => {
    return (
      <div>
        {renderSearchNFTsHeader()}
        {renderSearchNFTsBody()}
      </div>
    );
  }

  return (
    <>
      <ImageModalComponent/>
      <Container fluid={true} className="bg-secondary text-light">
        {renderSearchNFTs()}
        <div className="page-selector-class">
          <Row>
            <Button className="page-selector-left" onClick={() => onPageClick(0)}><FontAwesomeIcon icon={faArrowLeft} /></Button>
            <Col className="page-selector-center">
              <Row>
                <Col className="page-selector-center-left"><Form.Label>Page</Form.Label></Col>
                <Col className="page-selector-center-right"><Form.Control size="sm" placeholder="Page" type="text" value={props.formValues.page} onChange={updatePageNumber} /></Col>
              </Row>
            </Col>
            <Button className="page-selector-right" onClick={() => onPageClick(1)}><FontAwesomeIcon icon={faArrowRight} /></Button>
          </Row>
        </div>
      </Container>
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    postSearchNFTs: (request: PostSearchNFTRequest) => dispatch(postSearchNFT(request)),
    getNFTHistory: (request: number) => dispatch(getNFTHistory(request)),
    updateNFTForm: (filters: WebNFTFilters) => dispatch(updateNFTForm(filters)),
    updateNFTFormCategory: (category: string) => dispatch(updateNFTFormCategory(category)),
    closeNFTHistory: () => dispatch(closeNFTHistory()),
    toggleImageModal: (url: string, name: string) => dispatch(toggleImageModal(url, name))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.LoginState.authTokenSet,
    
    isLoadingNFTs: state.NFTState.isLoadingNFTs,
    hasError: state.NFTState.hasError,
    errorMessage: state.NFTState.errorMessage,
    
    nfts: state.NFTState.nfts,
  
    isLoadingHistory: state.NFTState.isLoadingHistory,
    nftHistory: state.NFTState.nftHistory,
    hasLoadingHistoryError: state.NFTState.hasLoadingHistoryError,
    loadingHistoryError: state.NFTState.loadingHistoryError,
    historyDGoodId: state.NFTState.historyDGoodId,
  
    formValues: state.NFTState.formValues,
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchNFTs);