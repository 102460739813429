import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Form, Col, Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from '../../components/LoadingComponent/Loading';
import { ErrorComponent } from '../../components/ErrorComponent/Error';
import './leaderboards.css';
import { leaderboardTypeEnum } from '../../common/enums/leaderboardTypeEnum';
import LeaderboardsFormFields from '../../common/types/LeaderboardsFormFields';
import { KeyValuePair } from '../../common/types/KeyValuePair';
import LeaderboardListItem from '../../common/types/LeaderboardListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { timeTypeEnum } from '../../common/enums/timeTypeEnum';
import PostLeaderboardRequest from '../../common/types/messages/PostLeaderboardRequest';
import { postLeaderboards, updateLeaderboardsForm } from '../../store/actions/leaderboardActions';
import { nftCategoriesEnum } from '../../common/enums/nftCategoriesEnum';
import { LeaderboardTableComponent } from '../../components/LeaderboardTableComponent/LeaderboardTableComponent';

interface StateProps {
  authTokenSet: boolean,
  hasLoadingInfoError: boolean,
  infoErrorMessage: string,
  isLoadingInfo: boolean,

  leaderboard: LeaderboardListItem[];

  isLoadingLeaderboard: boolean;
  hasErrorLeaderboard: boolean;
  errorLeaderboardMessage: string;

  leaderboardForm: LeaderboardsFormFields;
};

interface DispatchProps {
  postLeaderboards: (request: PostLeaderboardRequest) => void;
  updateLeaderboardsForm: (formValues: LeaderboardsFormFields) => void;
}

export type LeaderboardsProps = DispatchProps & StateProps;

export const Leaderboards = (props: LeaderboardsProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.authTokenSet) {
      navigate("/Unauthorized");
    }
  }, [navigate, props.authTokenSet]);

  const downloadCSV = async () => {

    let filename = `${leaderboardTypeEnum.filter((c) => c.key === props.leaderboardForm.Type)[0].value.replace(/\s/g, '')}_leaderboard.csv`;

    let entryData = [] as string[];
    let headerString = "Rank,Username,Value,";
    
    if (props.leaderboardForm.Type === 2) {
      headerString += "TeamName,"
      entryData = props.leaderboard.map((e) => { 
        return e.rank + "," + 
          e.uplandUsername + "," +
          e.value + "," +
          e.additionalInformation + ","
      });
    } else {
      entryData = props.leaderboard.map((e) => { 
        return e.rank + "," + 
          e.uplandUsername + "," +
          e.value + ","
      });
    }

    let headerData = [headerString];
    let csvData = headerData.concat(entryData).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } 
  }

  const handleEnterKeyPress = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      getLeaderboards();
    }
  }

  const onUpdateFilters = (formValues: LeaderboardsFormFields) => {
    props.updateLeaderboardsForm(formValues);
  }

  const renderTypeSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Type <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.leaderboardForm.Type}
              onChange={e => {
                props.updateLeaderboardsForm({
                  ...props.leaderboardForm,
                  Type: parseInt(e.target.value),
                  page: 1,
                });
            }}>
              {leaderboardTypeEnum.map((c: KeyValuePair) => <option key={c.key} value={c.key}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderTimeTypeSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Period <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.leaderboardForm.TimeType}
              onChange={e => {
                props.updateLeaderboardsForm({
                  ...props.leaderboardForm,
                  TimeType: e.target.value,
                  page: 1,
                });
            }}>
              {timeTypeEnum.map((c: KeyValuePair) => <option key={c.key} value={c.value}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderNFTTypeSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Category <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.leaderboardForm.NFTType}
              onChange={e => {
                props.updateLeaderboardsForm({
                  ...props.leaderboardForm,
                  NFTType: e.target.value,
                  page: 1,
                });
            }}>
              {nftCategoriesEnum.map((c: KeyValuePair) => <option key={c.key} value={c.key}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderTeamNameBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Team</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.leaderboardForm.TeamName}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.leaderboardForm,
                  TeamName: e.target.value
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
        <Row>
          <h5>Does Not Include Collection Boosts!</h5>
        </Row>
      </div>
    );
  }

  const renderTimeBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Number</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.leaderboardForm.TimeValue}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                if(Number(e.target.value) || e.target.value === "")
                {
                  onUpdateFilters({
                    ...props.leaderboardForm,
                    TimeValue: Number(e.target.value)
                  });
                }
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const getLeaderboards = () => {

    let additionalInfo = "";
    let fromDate = new Date();

    if (props.leaderboardForm.Type === 2)
    {
      additionalInfo = props.leaderboardForm.TeamName;
    } else if (props.leaderboardForm.Type === 9)
    {
      additionalInfo = props.leaderboardForm.NFTType;
    } else if (shouldDisplayTimeBox()) {
      switch(props.leaderboardForm.TimeType) {
        case "Hour":
          fromDate.setUTCHours(fromDate.getUTCHours() - props.leaderboardForm.TimeValue);
          break;
        case "Day":
          fromDate.setUTCDate(fromDate.getUTCDate() - props.leaderboardForm.TimeValue);
          break;
        case "Month":
          fromDate.setUTCMonth(fromDate.getUTCMonth() - props.leaderboardForm.TimeValue);
          break;
        case "Year":
          fromDate.setUTCFullYear(fromDate.getUTCFullYear() - props.leaderboardForm.TimeValue);
          break;
      }
    }

    props.postLeaderboards({
      type: props.leaderboardForm.Type,
      fromDate: fromDate,
      additionalInfo: additionalInfo
    });
  }

  const renderColumnOne = () => {
    if (props.isLoadingLeaderboard) {
      return (
      <div>
        <Row><h2>Leaderboards</h2></Row>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      );
    } else {
      return (
        <div>
          <Row><h2>Leaderboards</h2></Row>
          <Row>
            <Button variant="dark" onClick={getLeaderboards} disabled={props.isLoadingLeaderboard}>Get Leaderboard</Button>
            <Button className="for-sale-download-csv" variant="dark" onClick={downloadCSV} disabled={props.isLoadingLeaderboard}>Download CSV <FontAwesomeIcon icon={faDownload} /></Button>
          </Row>
        </div>
      );
    }
  }

  const shouldDisplayTimeBox = () => {
    return props.leaderboardForm.Type === 7 
      || props.leaderboardForm.Type === 8 
      || props.leaderboardForm.Type === 10 
      || props.leaderboardForm.Type === 11 
      || props.leaderboardForm.Type === 13 
      || props.leaderboardForm.Type === 14
      || props.leaderboardForm.Type === 15
      || props.leaderboardForm.Type === 16
      || props.leaderboardForm.Type === 18
      || props.leaderboardForm.Type === 19
      || props.leaderboardForm.Type === 20
      || props.leaderboardForm.Type === 21
  }

  const renderColumnTwo = () => {
    return (
      <div>
        {renderTypeSelect()}
        {props.leaderboardForm.Type === 2 && renderTeamNameBox()}
        {props.leaderboardForm.Type === 9 && renderNFTTypeSelect()}
        {shouldDisplayTimeBox() && renderTimeTypeSelect()}
        {shouldDisplayTimeBox() && renderTimeBox()}
      </div>
    );
  }

  const renderLeaderboardsHeader = () => {
    return (
      <Container fluid={true} className="bg-secondary text-light">
        <Row className="profile-header leaderboards-min-width">
          <Col className="input-form-column-one input-form-column header-info-column-width-250">
            {renderColumnOne()}
          </Col>
          <Col className="input-form-column header-info-column-width-450">
            {renderColumnTwo()}
          </Col>
        </Row>
      </Container>
    );
  }

  const renderLeaderboardsBody = () => {
    return (
      <div className="table-max-height">
        <LeaderboardTableComponent 
          entries={props.leaderboard
            .filter(p => p.uplandUsername.includes(props.leaderboardForm.nameFilter)
              && p.additionalInformation.includes(props.leaderboardForm.additionalInfoFilter))
            .slice((props.leaderboardForm.page - 1) * 100, (props.leaderboardForm.page * 100))} 
          type={props.leaderboardForm.Type}
          formValues={props.leaderboardForm}
          updateFormValues={props.updateLeaderboardsForm} />
      </div>
    );
  }

  const onPageClick = (direction: number) => {
    if (direction === 0 && props.leaderboardForm.page > 1) {
      onUpdateFilters({
        ...props.leaderboardForm,
        page: props.leaderboardForm.page - 1
      })
    } else if (direction === 1 && props.leaderboardForm.page) {
      onUpdateFilters({
        ...props.leaderboardForm,
        page: props.leaderboardForm.page + 1
      })
    }
  }

  const updatePageNumber = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    let parsedNum = parseInt(changeEvent.currentTarget.value);

    if (isNaN(parsedNum)) {
      parsedNum = 0;
    }
    onUpdateFilters({
      ...props.leaderboardForm,
      page: parsedNum
    });
  }

  const renderLeaderboards = () => {
    if (props.isLoadingInfo) {
      return (
        <LoadingComponent />
      );
    } else if (props.hasLoadingInfoError) {
      return (
        <ErrorComponent errorMessage={props.infoErrorMessage} />
      );
    } else {
      return (
        <div>
          {renderLeaderboardsHeader()}
          {renderLeaderboardsBody()}
        </div>
      );
    }
  }

  return (
    <>
      <Container fluid={true} className="bg-secondary text-light">
        {renderLeaderboards()}
        <div className="page-selector-class">
          <Row>
            <Button className="page-selector-left" onClick={() => onPageClick(0)}><FontAwesomeIcon icon={faArrowLeft} /></Button>
            <Col className="page-selector-center">
              <Row>
                <Col className="page-selector-center-left"><Form.Label>Page</Form.Label></Col>
                <Col className="page-selector-center-right"><Form.Control size="sm" placeholder="Page" type="text" value={props.leaderboardForm.page} onChange={updatePageNumber} /></Col>
              </Row>
            </Col>
            <Button className="page-selector-right" onClick={() => onPageClick(1)}><FontAwesomeIcon icon={faArrowRight} /></Button>
          </Row>
        </div>
      </Container>
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    postLeaderboards: (request: PostLeaderboardRequest) => dispatch(postLeaderboards(request)),
    updateLeaderboardsForm: (formValues: LeaderboardsFormFields) => dispatch(updateLeaderboardsForm(formValues))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.LoginState.authTokenSet,
    hasLoadingInfoError: state.InfoState.hasError,
    infoErrorMessage: state.InfoState.errorMessage,
    isLoadingInfo: state.InfoState.isLoadingCities || state.InfoState.isLoadingCollections || state.InfoState.isLoadingNeighborhoods,

    leaderboard: state.LeaderboardsState.leaderboard,

    isLoadingLeaderboard: state.LeaderboardsState.isLoadingLeaderboards,
    hasErrorLeaderboard: state.LeaderboardsState.hasError,
    errorLeaderboardMessage: state.LeaderboardsState.errorMessage,

    leaderboardForm: state.LeaderboardsState.leaderboardsForm
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboards);