import { Reducer } from 'redux';
import { PropertyState } from '../states/propertyState';
import WebForSaleFilters from '../../common/types/WebForSaleFilters';
import {
  PropertyActionTypes,
  PostPropertiesForSaleActionType,
  PostPropertiesForSaleCompletedActionType,
  PostPropertiesForSaleFailedActionType,
  PostPropertiesUnmintedActionType,
  PostPropertiesUnmintedCompletedActionType,
  PostPropertiesUnmintedFailedActionType,
  UpdateForSaleFormActionType,
  UpdateUnmintedFormActionType,
  GetPropertyHistoryActionType,
  GetPropertyHistoryCompletedActionType,
  GetPropertyHistoryFailedActionType,
  ClosePropertyHistoryActionType
} from '../actions/propertyActions';

export type PropertyActions = 
  PostPropertiesForSaleActionType
  | PostPropertiesForSaleCompletedActionType
  | PostPropertiesForSaleFailedActionType
  | PostPropertiesUnmintedActionType
  | PostPropertiesUnmintedCompletedActionType
  | PostPropertiesUnmintedFailedActionType
  | UpdateForSaleFormActionType
  | UpdateUnmintedFormActionType
  | GetPropertyHistoryActionType
  | GetPropertyHistoryCompletedActionType
  | GetPropertyHistoryFailedActionType
  | ClosePropertyHistoryActionType;

const initialForSaleFormState: WebForSaleFilters = {
  cityId: 1,
  owner: '',
  address: '',
  neighborhoodIds: [],
  collectionIds: [],
  buildings: [],
  fsa: undefined,
  currency: 'Any',
  asc: true,
  orderBy: 'Price',
  pageSize: 100,
  page: 1
}

const initialUnmintedFormState: WebForSaleFilters = {
  cityId: 1,
  owner: '',
  address: '',
  neighborhoodIds: [],
  collectionIds: [],
  buildings: [],
  fsa: undefined,
  currency: 'Any',
  asc: true,
  orderBy: 'Mint',
  pageSize: 100,
  page: 1
}

const initialState: PropertyState = {
  propertiesForSale: [],
  propertiesUnminted: [],

  isLoadingForSaleProperties: false,
  hasErrorForSaleProp: false,
  errorForSalePropsMessage: '',

  isLoadingUnmintedProperties: false,
  hasErrorUnmintedProp: false,
  errorUnmintedPropsMessage: '',

  forSaleFormState: initialForSaleFormState,

  unmintedFormState: initialUnmintedFormState,
  unmintedSelectedFSA: 'Any',

  isLoadingHistory: false,
  propertyHistory: [],
  hasLoadingHistoryError: false,
  loadingHistoryError: '',
  historyPropertyId: 0
}

export const PropertyReducer: Reducer<PropertyState> = (state = initialState, action: PropertyActions): PropertyState => {
  switch(action.type) {
    case PropertyActionTypes.PostPropertiesForSale:
      return {
        ...state,
        isLoadingForSaleProperties: true,
        hasErrorForSaleProp: false,
        errorForSalePropsMessage: '',
        propertiesForSale: []
      };
    case PropertyActionTypes.PostPropertiesForSaleCompleted:
      return {
        ...state,
        isLoadingForSaleProperties: false,
        hasErrorForSaleProp: false,
        errorForSalePropsMessage: '',
        forSaleFormState: {
          ...state.forSaleFormState,
        },
        propertiesForSale: action.payload!.response.properties
      };
    case PropertyActionTypes.PostPropertiesForSaleFailed:
      return {
        ...state,
        isLoadingForSaleProperties: false,
        hasErrorForSaleProp: true,
        errorForSalePropsMessage: action.payload!.error as any,
        forSaleFormState: {
          ...state.forSaleFormState,
          page: 1,
        },
        propertiesForSale: []
      };
    case PropertyActionTypes.PostPropertiesUnminted:
      return {
        ...state,
        isLoadingUnmintedProperties: true,
        hasErrorUnmintedProp: false,
        errorUnmintedPropsMessage: '',
        propertiesUnminted: []
      };
    case PropertyActionTypes.PostPropertiesUnmintedCompleted:
      return {
        ...state,
        isLoadingUnmintedProperties: false,
        hasErrorUnmintedProp: false,
        errorUnmintedPropsMessage: '',
        unmintedFormState: {
          ...state.unmintedFormState,
        },
        propertiesUnminted: action.payload!.response.properties
      };
    case PropertyActionTypes.PostPropertiesUnmintedFailed:
      return {
        ...state,
        isLoadingUnmintedProperties: false,
        hasErrorUnmintedProp: true,
        errorUnmintedPropsMessage: action.payload!.error as any,
        unmintedFormState: {
          ...state.unmintedFormState,
          page: 1,
        },
        propertiesUnminted: []
      };
    case PropertyActionTypes.UpdateForSaleForm:
      return {
        ...state,
        forSaleFormState: action.payload!.filters,
      };
    case PropertyActionTypes.UpdateUnmintedForm:
      return {
        ...state,
        unmintedFormState: action.payload!.filters,
        unmintedSelectedFSA: action.payload!.fsas,
      };

    case PropertyActionTypes.GetPropertyHistory:
      return {
        ...state,
        isLoadingHistory: true,
        propertyHistory: [],
        hasLoadingHistoryError: false,
        loadingHistoryError: '',
        historyPropertyId: action.payload!.request
      }
    case PropertyActionTypes.GetPropertyHistoryCompleted:
      return {
        ...state,
        isLoadingHistory: false,
        propertyHistory: action.payload!.response.history,
        hasLoadingHistoryError: false,
        loadingHistoryError: ''
      }
    case PropertyActionTypes.GetPropertyHistoryFailed:
      return {
        ...state,
        isLoadingHistory: false,
        propertyHistory: [],
        hasLoadingHistoryError: true,
        loadingHistoryError: action.payload!.error.message,
        historyPropertyId: 0
      }
    case PropertyActionTypes.ClosePropertyHistory:
      return {
        ...state,
        isLoadingHistory: false,
        hasLoadingHistoryError: false,
        loadingHistoryError: "",
        propertyHistory: [],
        historyPropertyId: 0
      }
    default:
      return state;
  }
}

export default PropertyReducer;