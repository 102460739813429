import { KeyValuePair } from '../types/KeyValuePair';

export const leaderboardTypeEnum: KeyValuePair[] = [
  { key: 1, value: "Spark"   },
  { key: 2, value: "NFLPA Fan Points" },
  { key: 3, value: "Property Count"   },
  { key: 4, value: "Monthly Earnings"   },
  { key: 5, value: "Total UP2"   },
  { key: 6, value: "Total Mint"   },
  { key: 7, value: "USD Sales"   },
  { key: 8, value: "UPX Sales"   },
  { key: 9, value: "NFT Count"   },
  { key: 10, value: "Properties Minted"   },
  { key: 11, value: "Minted UPX Total"   },
  { key: 12, value: "Collection Properties"   },
  { key: 13, value: "Spent USD"   },
  { key: 14, value: "Spent UPX"   },
  { key: 15, value: "Net USD" },
  { key: 16, value: "Net UPX"},
  { key: 17, value: "Liquid UPX"},
  { key: 18, value: "Open Offers"   },
  { key: 19, value: "Cancelled Offers"   },
  { key: 20, value: "Completed Offers" },
  { key: 21, value: "Rejected Offers"   },
  { key: 22, value: "Networth" },
  { key: 23, value: "Annual Return" },
  { key: 24, value: "Raw FIFA Fan Points" },
];