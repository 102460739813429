import WebNFT from '../../common/types/WebNFT';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faLink, faList } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button, Spinner, ModalTitle, ModalBody, ModalFooter, Modal } from 'react-bootstrap'
import WebNFTHistory from '../../common/types/WebNFTHistory';
import './NFTTableStyle.css';

export interface OwnProps {
  nft: WebNFT,
  category: string,
  getNFTHistory: Function,
  isLoadingHistory: boolean,
  closeNFTHistory: Function,
  hasLoadingHistoryError: boolean,
  nftHistory: WebNFTHistory[],
  historyDGoodId: number,
  toggleImageModal: Function
}

export const NFTTableEntry = (props: OwnProps) => {

  const renderHistoryModal = () => {
    if (props.historyDGoodId !== props.nft.dGoodId) {
      return;
    }

    if (props.isLoadingHistory) {
      return (
        <Modal show={props.isLoadingHistory} onHide={() => props.closeNFTHistory()} animation={false} centered>
            <ModalTitle id="contained-modal-title-vcenter">NFT History</ModalTitle>
            <ModalBody>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </ModalBody>
            <ModalFooter>
              <Button variant="primary" onClick={() => props.closeNFTHistory()}>Close</Button>
            </ModalFooter>
        </Modal>
      );
    } else if (props.hasLoadingHistoryError) {
      return (
        <Modal show={props.hasLoadingHistoryError} onHide={() => props.closeNFTHistory()} animation={false} centered>
            <ModalTitle id="contained-modal-title-vcenter">NFT History</ModalTitle>

            <ModalBody>
              Error Loading NFT History
            </ModalBody>

            <ModalFooter>
              <Button variant="primary" onClick={() => props.closeNFTHistory()}>Close</Button>
            </ModalFooter>
        </Modal>
      );
    } else if (props.nftHistory && props.nftHistory.length > 0) {
      return (
        <Modal show={props.nftHistory && props.nftHistory.length > 0} onHide={() => props.closeNFTHistory()} animation={false} centered>
            <ModalTitle className="history-title-pad">NFT History</ModalTitle>

            <ModalBody>
              {props.nftHistory.map(h => <Row><Col>{new Date(h.dateTime).toLocaleString()}</Col><Col>{h.event}</Col></Row>)}
            </ModalBody>

            <ModalFooter>
              <Button variant="primary" onClick={() => props.closeNFTHistory()}>Close</Button>
            </ModalFooter>
        </Modal>
      );
    }
  }

  const getSpecificEntries = () => {
    switch (props.category)
    {
      case "essential":
        return (
          <>
            <td>{props.nft.team}</td>
            <td>{props.nft.year}</td>
            <td>{props.nft.position}</td>
            <td>{props.nft.fanPoints}</td>
            <td>{props.nft.modelType}</td>
            <td>{props.nft.isVariant ? "Yes" : ""}</td>
            <td>{props.nft.legitType}</td>
          </>
        );
      case "memento":
        return (
          <>
            <td>{props.nft.team}</td>
            <td>{props.nft.year}</td>
            <td>{props.nft.position}</td>
            <td>{props.nft.fanPoints}</td>
            <td>{props.nft.modelType}</td>
            <td>{props.nft.gameDate !== null ? new Date(props.nft.gameDate).toLocaleDateString() : ''}</td>
            <td>{props.nft.opponent}</td>
            <td>{props.nft.homeTeam}</td>
            <td>{props.nft.legitType}</td>
          </>
        );
      case "blkexplorer":
        return (
          <>
            <td>{props.nft.seriesName}</td>
            <td>{props.nft.rarity}</td>
            <td>{props.nft.description.length > 70 ? props.nft.description.substring(0,70) + "..." : props.nft.description}</td>
          </>
        );
      case "spirithlwn":
        return (
          <>
            <td>{props.nft.rarity}</td>
          </>
        );
      case "structornmt":
        return (
          <>
            <td>{props.nft.rarity}</td>
            <td>{props.nft.buildingType}</td>
          </>
        );
      case "structure":
        return (
          <>
            <td>{props.nft.fullAddress}</td>
          </>
        );
      case "landvehicle":
        return (
          <>
          </>
        );
      case "outdoordecor":
        return (
          <>
            <td>{props.nft.manufacturerName}</td>
          </>
        );
      case "pass":
        return (
          <>
            <td>{props.nft.year}</td>
            <td>{props.nft.fanPoints}</td>
            <td>{props.nft.modelType}</td>
            <td>{props.nft.legitType}</td>
          </>
        );
    }
  }

  return (
    <>
      {renderHistoryModal()}
      <tr className="nft-table-entry-centering">
        <td>{props.nft.serialNumber} / {props.nft.currentSupply} / {props.nft.maxSupply}</td>
        <td>{props.nft.name}</td>
        {getSpecificEntries()}
        <td>{props.nft.owner}</td>
        <td>
          <a className="nft-table-icon-padding">
            <FontAwesomeIcon icon={faList} onClick={() => props.getNFTHistory(props.nft.dGoodId)}/>
          </a>
          <a className="nft-table-icon-padding">
            <FontAwesomeIcon icon={faImage} onClick={() => props.toggleImageModal(props.nft.image, props.nft.name)}/>
          </a>
          <a href={props.nft.link} target="_blank" className="nft-table-icon-padding">
            <FontAwesomeIcon icon={faLink}/>
          </a>
        </td>
      </tr>
    </>
  );
}