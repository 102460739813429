import Action from './baseAction';
import LeaderboardsFormFields from '../../common/types/LeaderboardsFormFields';
import PostLeaderboardRequest from '../../common/types/messages/PostLeaderboardRequest';
import PostLeaderboardResponse from '../../common/types/messages/PostLeaderboardResponse';

export enum LeaderboardsActionTypes {
  PostLeaderboards = 'POST_LEADERBOARDS',
  PostLeaderboardsCompleted = 'POST_LEADERBOARDS_COMPLETED',
  PostLeaderboardsFailed = 'POST_LEADERBOARDS_FAILED',
  UpdateLeaderboardsForm = 'UPDATE_LEADERBOARDS_FORM'
}

export type PostLeaderboardsPayloadType = { request: PostLeaderboardRequest };
export type PostLeaderboardsActionType = Action<LeaderboardsActionTypes.PostLeaderboards, PostLeaderboardsPayloadType>;
export const postLeaderboards = (request: PostLeaderboardRequest): PostLeaderboardsActionType => ({type: LeaderboardsActionTypes.PostLeaderboards, payload: { request } });

export type PostLeaderboardsCompletedPayloadType = { response: PostLeaderboardResponse; };
export type PostLeaderboardsCompletedActionType = Action<LeaderboardsActionTypes.PostLeaderboardsCompleted, PostLeaderboardsCompletedPayloadType>;
export const postLeaderboardsCompleted = (response: PostLeaderboardResponse): PostLeaderboardsCompletedActionType => ({type: LeaderboardsActionTypes.PostLeaderboardsCompleted, payload: { response } });

export type PostLeaderboardsFailedPayloadType = { error: Error; };
export type PostLeaderboardsFailedActionType = Action<LeaderboardsActionTypes.PostLeaderboardsFailed, PostLeaderboardsFailedPayloadType>;
export const postLeaderboardsFailed = (error: Error): PostLeaderboardsFailedActionType => ({type: LeaderboardsActionTypes.PostLeaderboardsFailed, payload: { error } });

export type UpdateLeaderboardsFormPayloadType = { formValues: LeaderboardsFormFields };
export type UpdateLeaderboardsFormActionType = Action<LeaderboardsActionTypes.UpdateLeaderboardsForm, UpdateLeaderboardsFormPayloadType>;
export const updateLeaderboardsForm = (formValues: LeaderboardsFormFields): UpdateLeaderboardsFormActionType => ({type: LeaderboardsActionTypes.UpdateLeaderboardsForm, payload: { formValues } });

