import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { closePropertyHistory } from '../../store/actions/propertyActions';
import { Row, Col, Button, Spinner, ModalTitle, ModalBody, ModalFooter, Modal } from 'react-bootstrap'
import UIPropertyHistory from '../../common/types/UIPropertyHistory';
import './PropertyHistoryTableStyle.css'

interface StateProps {
  isLoadingHistory: boolean,
  propertyHistory: UIPropertyHistory[],
  hasLoadingHistoryError: boolean,
  loadingHistoryError: string,
  historyPropertyId: number
};

interface DispatchProps {
  closePropertyHistory: () => void;
}

export type PropertyHistoryProps = DispatchProps & StateProps;


const PropertyHistoryComponent = (props: PropertyHistoryProps) => {

  const renderHistoryModal = () => {
    if (props.isLoadingHistory) {
      return (
        <Modal size="lg" show={props.isLoadingHistory} onHide={() => props.closePropertyHistory()} animation={false} centered>
            <ModalTitle id="contained-modal-title-vcenter">Property History</ModalTitle>
            <ModalBody>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </ModalBody>
            <ModalFooter>
              <Button variant="primary" onClick={() => props.closePropertyHistory()}>Close</Button>
            </ModalFooter>
        </Modal>
      );
    } else if (props.hasLoadingHistoryError) {
      return (
        <Modal size="lg" show={props.hasLoadingHistoryError} onHide={() => props.closePropertyHistory()} animation={false} centered>
            <ModalTitle id="contained-modal-title-vcenter">Property History</ModalTitle>

            <ModalBody>
              Error Loading NFT History
            </ModalBody>

            <ModalFooter>
              <Button variant="primary" onClick={() => props.closePropertyHistory()}>Close</Button>
            </ModalFooter>
        </Modal>
      );
    } else if (props.propertyHistory && props.propertyHistory.length > 0) {
      return (
        <Modal size="lg" show={props.propertyHistory && props.propertyHistory.length > 0} onHide={() => props.closePropertyHistory()} animation={false} centered>
            <ModalTitle className="history-title-pad">Property History</ModalTitle>

            <ModalBody>
              {props.propertyHistory.map(h => 
                <Row className="property-history-table-entry-bottom-border">
                  <Col>{new Date(h.dateTime).toLocaleString()}</Col>
                  <Col xs={2}>{h.action}</Col>
                  <Col>{h.price}</Col>
                  <Col xs={2}>{h.newOwner}</Col>
                </Row>)}
            </ModalBody>

            <ModalFooter>
              <Button variant="primary" onClick={() => props.closePropertyHistory()}>Close</Button>
            </ModalFooter>
        </Modal>
      );
    }
  }


  return (
    <>
      {renderHistoryModal()}
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    closePropertyHistory: () => dispatch(closePropertyHistory())
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    isLoadingHistory: state.PropertyState.isLoadingHistory,
    propertyHistory: state.PropertyState.propertyHistory,
    hasLoadingHistoryError: state.PropertyState.hasLoadingHistoryError,
    historyPropertyId: state.PropertyState.historyPropertyId,
    loadingHistoryError: state.PropertyState.loadingHistoryError
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyHistoryComponent);