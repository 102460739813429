import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Form, Col, Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from '../../components/LoadingComponent/Loading';
import { ErrorComponent } from '../../components/ErrorComponent/Error';
import './forSaleProperties.css';
import WebCollection from '../../common/types/WebCollection';
import WebNeighborhood from '../../common/types/WebNeighborhood';
import WebCity from '../../common/types/WebCity';
import { KeyValuePair } from '../../common/types/KeyValuePair';
import CachedForSaleProperty from '../../common/types/CachedForSaleProperty';
import WebForSaleFilters from '../../common/types/WebForSaleFilters';
import PostPropertiesForSaleRequest from '../../common/types/messages/PostPropertiesForSaleRequest';
import { postPropertiesForSale, updateForSaleForm, getPropertyHistory } from '../../store/actions/propertyActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ForSalePropertyTableComponent } from '../../components/ForSalePropertyTableComponent/ForSalePropertyTableComponent';
import { orderBys } from '../../common/enums/orderByEnum';
import { currencies } from '../../common/enums/currencyEnum';
import { buildings } from '../../common/enums/buildingsEnum';
import { useAppContext } from  '../../common/app-context';
import { ApiService } from '../../common/api';
import PropertyHistoryComponent from '../../components/PropertyHistoryComponent/PropertyHistoryComponent';
import { MultiSelect } from "react-multi-select-component"
import { LabelValuePair } from '../../common/types/LabelValuePair';
import { CollectionSquare } from '../../components/CollectionSquareComponent/CollectionSquareComponent';

interface StateProps {
  authTokenSet: boolean,

  isLoadingCollections: boolean,
  isLoadingNeighborhoods: boolean,
  isLoadingCities: boolean,
  collectionsLoaded: boolean,
  neighborhoodsLoaded: boolean,
  citiesLoaded: boolean,
  hasLoadingInfoError: boolean,
  infoErrorMessage: string,

  collections: WebCollection[],
  neighborhoods: WebNeighborhood[],
  cities: WebCity[],

  propertiesForSale: CachedForSaleProperty[];

  isLoadingForSaleProperties: boolean;
  hasErrorForSaleProp: boolean;
  errorForSalePropsMessage: string;

  forSaleFormState: WebForSaleFilters;
};

interface DispatchProps {
  postPropertiesForSale: (request: PostPropertiesForSaleRequest) => void;
  updateForSaleForm: (filters: WebForSaleFilters) => void;
  getPropertyHistory: (request: number) => void;
}

export type ForSalePropertiesProps = DispatchProps & StateProps;

export const ForSaleProperties = (props: ForSalePropertiesProps) => {
  const context = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.authTokenSet) {
      navigate("/Unauthorized");
    }

    if(!props.isLoadingForSaleProperties) {
      searchForSaleProperties();
    }
  }, [navigate, props.authTokenSet, props.forSaleFormState.page, props.forSaleFormState.cityId]);

  const downloadCSV = async () => {

    const apiService: ApiService = context.apiService;

    let filename = `${props.cities.filter((c) => c.cityId === props.forSaleFormState.cityId)[0].name.replace(/\s/g, '')}_forsale_properties.csv`;

    const csvData = (await apiService.postPropertiesForSale$({filters: props.forSaleFormState, asCSV: true}).toPromise()).response.csvString;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } 
  }

  const handleEnterKeyPress = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      searchForSaleProperties();
    }
  }

  const createLabelValue = (pair: KeyValuePair) => {
    return {
      label: pair.value,
      value: pair.value
    } as LabelValuePair
  }

  const createLabelValueFromCollection = (collection: WebCollection) => {
    return {
      label: <><CollectionSquare key={collection.id} collection={collection} displayRight={false} disableHover={true} /> {collection.name}</>,
      value: "" + collection.id
    } as LabelValuePair
  }

  const createLabelValueFromNeighborhood = (neighborhood: WebNeighborhood) => {
    return {
      label: neighborhood.name,
      value: "" + neighborhood.id
    } as LabelValuePair
  }


  const onNeighborhoodsSelect = (selectedList: LabelValuePair[]) =>{
    props.updateForSaleForm({
      ...props.forSaleFormState,
      neighborhoodIds: selectedList.map((c) => parseInt(c.value))
    });
  }

  const renderNeighborhoodsSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Neighborhoods <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col xs={7}>
            <MultiSelect
              options={props.neighborhoods.filter(n => n.cityId === props.forSaleFormState.cityId).map((b) => createLabelValueFromNeighborhood(b))}
              value={props.neighborhoods.filter(n => props.forSaleFormState.neighborhoodIds.includes(n.id)).map((b) => createLabelValueFromNeighborhood(b))}
              onChange={onNeighborhoodsSelect}
              labelledBy="Select"
              className="multi-select"
            />
          </Col>
        </Row>
      </div>
    )
  }

  const onBuildingsSelect = (selectedList: KeyValuePair[]) =>{
    props.updateForSaleForm({
      ...props.forSaleFormState,
      buildings: selectedList.map((c) => c.value)
    });
  }
  
  const renderBuildingsSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Buildings <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col xs={7}>
            <MultiSelect
              options={buildings.filter((b) => b.value.split(',').length === 1 || b.value.split(', ')[1] === props.cities.filter(c => c.cityId === props.forSaleFormState.cityId)[0].name).map((b) => createLabelValue(b))}
              value={ buildings.filter((b) => props.forSaleFormState.buildings.includes(b.value)).map((b) => createLabelValue(b))}
              onChange={onBuildingsSelect}
              labelledBy="Select"
              className="multi-select"
            />
          </Col>
        </Row>
      </div>
    )
  }

  const onCollectionSelect = (selectedList: LabelValuePair[]) => {
    props.updateForSaleForm({
      ...props.forSaleFormState,
      collectionIds: selectedList.map((c) => parseInt(c.value))
    });
  }

  const customCollectionValueRenderer = (selected: any, _options: any) => {

    if (!selected.length) {
      return "Select...";
    }

    if (selected.length === _options.length) {
      return "All items are selected."
    }

    return selected.map((s: any) => props.collections.filter(c => c.id === parseInt(s.value))[0].name).join(", ");
  };

  const renderCollectionsSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Collections <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col xs={7}>
            <MultiSelect
              options={props.collections.filter(c => c.cityId === props.forSaleFormState.cityId).map((b) => createLabelValueFromCollection(b))}
              value={props.collections.filter(c => props.forSaleFormState.collectionIds.includes(c.id)).map((b) => createLabelValueFromCollection(b))}
              onChange={onCollectionSelect}
              labelledBy="Select"
              className="multi-select"
              valueRenderer={customCollectionValueRenderer}
            />
          </Col>
        </Row>
      </div> 
    )
  }

  const renderCurrencySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Currency <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.forSaleFormState.currency}
              onChange={e => {
                props.updateForSaleForm({
                  ...props.forSaleFormState,
                  currency: e.target.value
                });
            }}>
              {currencies.map((c) => <option key={c.key} value={c.value}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderOrderBySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Order By <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.forSaleFormState.orderBy}
              onChange={e => {
                props.updateForSaleForm({
                  ...props.forSaleFormState,
                  orderBy: e.target.value
                });
            }}>
              {orderBys.map((c) => <option key={c.key} value={c.value}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderAscendingSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Direction <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.forSaleFormState.asc ? 'Ascending' : 'Descending'}
              onChange={e => {
                props.updateForSaleForm({
                  ...props.forSaleFormState,
                  asc: e.target.value === 'Ascending'
                });
            }}>
              <option key={'Ascending'} value={'Ascending'}>Ascending</option>
              <option key={'Descending'} value={'Descending'}>Descending</option>
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderCitySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>City <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.forSaleFormState.cityId}
              onChange={e => {
                props.updateForSaleForm({
                  ...props.forSaleFormState,
                  cityId: parseInt(e.target.value),
                  page: 1,
                  collectionIds: [],
                  neighborhoodIds: []
                });
            }}>
              {props.cities.filter(x => x.isPrimaryCity).sort((aVal, bVal) => aVal.name > bVal.name ? 1 : -1).map((c) => <option key={c.cityId} value={c.cityId}>{c.name}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderAddressBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Address</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.forSaleFormState.address}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                props.updateForSaleForm({
                  ...props.forSaleFormState,
                  address: e.target.value
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderOwnerBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={4}>
            <h5>Owner</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.forSaleFormState.owner}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                props.updateForSaleForm({
                  ...props.forSaleFormState,
                  owner: e.target.value
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const searchForSaleProperties= () => {
    props.postPropertiesForSale({filters: props.forSaleFormState, asCSV: false});
  }

  const renderColumnOne = () => {
    if (props.isLoadingCollections || props.isLoadingNeighborhoods || props.isLoadingForSaleProperties) {
      return (
      <div>
        <Row><h2>Properties For Sale</h2></Row>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      );
    } else {
      return (
        <div>
          <Row><h2>Properties For Sale</h2></Row>
          <Row>
            <Button variant="dark" onClick={searchForSaleProperties} disabled={props.isLoadingForSaleProperties}>Search Properties</Button>
            <Button className="for-sale-download-csv" variant="dark" onClick={downloadCSV} disabled={props.isLoadingForSaleProperties}>Download CSV <FontAwesomeIcon icon={faDownload} /></Button>
          </Row>
        </div>
      );
    }
  }

  const renderColumnTwo = () => {
    return (
      <div>
        {renderCitySelect()}
        {renderAddressBox()}
        {renderOwnerBox()}
      </div>
    );
  }

  const renderColumnThree = () => {
    return (
      <div>
        {renderOrderBySelect()}
        {renderAscendingSelect()}
        {renderCurrencySelect()}
      </div>
    );
  }

  const renderColumnFour = () => {
    return (
      <div>
        {renderCollectionsSelect()}
        {renderNeighborhoodsSelect()}
        {renderBuildingsSelect()}
      </div>
    );
  }

  const renderForSalePropertiesHeader = () => {
    return (
      <Container fluid={true} className="bg-secondary text-light">
        <Row className="profile-header header-min-width">
          <Col className="input-form-column-one input-form-column header-info-column-width-250">
            {renderColumnOne()}
          </Col>
          <Col className="input-form-column header-info-column-width-350">
            {renderColumnTwo()}
          </Col>
          <Col className="input-form-column header-info-column-width-350">
            {renderColumnThree()}
          </Col>
          <Col className="header-info-column-width-450-right">
            {renderColumnFour()}
          </Col>
        </Row>
      </Container>
    );
  }

  const renderForSalePropertiesBody = () => {
    return (
      <div className="table-max-height">
        <ForSalePropertyTableComponent properties={props.propertiesForSale} neighborhoods={props.neighborhoods} collections={props.collections} getPropertyHistory={props.getPropertyHistory}/>
      </div>
    );
  }

  const onPageClick = (direction: number) => {
    if (direction === 0 && props.forSaleFormState.page > 1) {
      props.updateForSaleForm({
        ...props.forSaleFormState,
        page: props.forSaleFormState.page - 1
      })
    } else if (direction === 1 && props.forSaleFormState.page) {
      props.updateForSaleForm({
        ...props.forSaleFormState,
        page: props.forSaleFormState.page + 1
      })
    }
  }

  const updatePageNumber = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    let parsedNum = parseInt(changeEvent.currentTarget.value);

    if (isNaN(parsedNum)) {
      parsedNum = 0;
    }
    props.updateForSaleForm({
      ...props.forSaleFormState,
      page: parsedNum
    });
  }

  const renderForSaleProperties = () => {
    if (props.isLoadingCollections || props.isLoadingNeighborhoods) {
      return (
        <LoadingComponent />
      );
    } else if (props.hasLoadingInfoError) {
      return (
        <ErrorComponent errorMessage={props.infoErrorMessage} />
      );
    } else {
      return (
        <div>
          {renderForSalePropertiesHeader()}
          {renderForSalePropertiesBody()}
        </div>
      );
    }
  }

  return (
    <>
      <PropertyHistoryComponent/>
      <Container fluid={true} className="bg-secondary text-light">
        {renderForSaleProperties()}
        <div className="page-selector-class">
          <Row>
            <Button className="page-selector-left" onClick={() => onPageClick(0)}><FontAwesomeIcon icon={faArrowLeft} /></Button>
            <Col className="page-selector-center">
              <Row>
                <Col className="page-selector-center-left"><Form.Label>Page</Form.Label></Col>
                <Col className="page-selector-center-right"><Form.Control size="sm" placeholder="Page" type="text" value={props.forSaleFormState.page} onChange={updatePageNumber} /></Col>
              </Row>
            </Col>
            <Button className="page-selector-right" onClick={() => onPageClick(1)}><FontAwesomeIcon icon={faArrowRight} /></Button>
          </Row>
        </div>
      </Container>
    </>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    postPropertiesForSale: (request: PostPropertiesForSaleRequest) => dispatch(postPropertiesForSale(request)),
    updateForSaleForm: (filters: WebForSaleFilters) => dispatch(updateForSaleForm(filters)),
    getPropertyHistory: (request: number) => dispatch(getPropertyHistory(request))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.LoginState.authTokenSet,
    
    isLoadingCollections: state.InfoState.isLoadingCollections,
    isLoadingNeighborhoods: state.InfoState.isLoadingNeighborhoods,
    isLoadingCities: state.InfoState.isLoadingCities,
    collectionsLoaded: state.InfoState.collections.length > 0,
    neighborhoodsLoaded: state.InfoState.neighborhoods.length > 0,
    citiesLoaded: state.InfoState.cities.length >0,
    hasLoadingInfoError: state.InfoState.hasError,
    infoErrorMessage: state.InfoState.errorMessage,

    collections: state.InfoState.collections,
    neighborhoods: state.InfoState.neighborhoods,
    cities: state.InfoState.cities,

    propertiesForSale: state.PropertyState.propertiesForSale,

    isLoadingForSaleProperties: state.PropertyState.isLoadingForSaleProperties,
    hasErrorForSaleProp: state.PropertyState.hasErrorForSaleProp,
    errorForSalePropsMessage: state.PropertyState.errorForSalePropsMessage,

    forSaleFormState: state.PropertyState.forSaleFormState,
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(ForSaleProperties);