import LeaderboardListItem from '../../common/types/LeaderboardListItem';

export interface OwnProps {
  entry: LeaderboardListItem,
  type: number
}

export const LeaderboardTableEntry = (props: OwnProps) => {
  
  const getSpecificEntries = () => {
    if (props.type === 2) {
      return (
        <>
          <td>{props.entry.additionalInformation}</td>
        </>
      );
    }
  }

  const formatNumberForType = (value: number) => {
    switch(props.type) {
      case 1:
      case 4:
      case 6:
      case 8:
      case 11:
      case 14:
      case 16:
      case 17:
      case 22:
      case 24:
        return value.toLocaleString(undefined, { minimumFractionDigits: 2 });

      case 7:
      case 13:
      case 15:
        return "$" + value.toLocaleString(undefined, { minimumFractionDigits: 2 });

      case 2:
      case 3:
      case 5:
      case 9:
      case 10:
      case 12:
      case 18:
      case 19:
      case 20:
      case 21:
      case 25:
        return value.toLocaleString(undefined, { minimumFractionDigits: 0 });
      
      case 23:
        return value.toLocaleString(undefined, {minimumFractionDigits: 2}) + " %";
    }
  }

  return (
    <>
      <tr className="nft-table-entry-centering">
        <td>{props.entry.rank}</td>
        <td>{props.entry.uplandUsername}</td>
        <td>{formatNumberForType(props.entry.value)}</td>
        {getSpecificEntries()}
      </tr>
    </>
  );
}