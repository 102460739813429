import { Table, Form } from 'react-bootstrap';
import { LeaderboardTableEntry } from './LeaderboardTableEntry';
import LeaderboardListItem from '../../common/types/LeaderboardListItem';
import LeaderboardsFormFields from '../../common/types/LeaderboardsFormFields';
import '../../areas/forSaleProperties/forSaleProperties.css';
import './LeaderboardTableComponent.css';

export interface OwnProps {
  entries: LeaderboardListItem[],
  type: number,
  formValues: LeaderboardsFormFields,
  updateFormValues: Function
}

export const LeaderboardTableComponent = (props: OwnProps) => {

  const updateUsernameFilter = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    props.updateFormValues({
      ...props.formValues,
      nameFilter: changeEvent.currentTarget.value
    } as LeaderboardsFormFields)
  }

  const updateAdditionalInfoFilter = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    props.updateFormValues({
      ...props.formValues,
      additionalInfoFilter: changeEvent.currentTarget.value
    } as LeaderboardsFormFields)
  }

  const getSpecificColumnHeaders = () => {
    if (props.type === 2) {
      return (
        <>
            <th>
              <div className="Leaderboard-Filter-col">
                <Form.Label>Team Name</Form.Label>
                <Form.Control className="Leaderboard-FilterBox-Max-Width" size="sm" type="text" value={props.formValues.additionalInfoFilter} onChange={updateAdditionalInfoFilter} />
              </div>
            </th>
          </>
      );
    }
  }

  return (
    <div className="bg-light leaderboards-min-width">
      <Table>
        <thead>
          <tr className="form-aligns">
            <th>
              <Form.Label>Rank</Form.Label>
            </th>
            <th>
              <div className="Leaderboard-Filter-col">
                <Form.Label>Username</Form.Label>
                <Form.Control className="Leaderboard-FilterBox-Max-Width" size="sm" type="text" value={props.formValues.nameFilter} onChange={updateUsernameFilter} />
              </div>
            </th>
            <th>
              <Form.Label>Value</Form.Label>
            </th>
            {getSpecificColumnHeaders()}
          </tr>
        </thead>
        <tbody>
          {props.entries && props.entries.map((e) => 
            <LeaderboardTableEntry 
              entry={e}
              type={props.type} 
          />)}
        </tbody>
      </Table>
    </div>
  );
}
