import React, { useEffect, useState } from 'react';
import { useAppContext } from  '../../common/app-context';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import WebCollection from '../../common/types/WebCollection';
import WebNeighborhood from '../../common/types/WebNeighborhood';
import WebSaleHistoryFilters from '../../common/types/WebSaleHistoryFilters';
import PostSaleHistoryRequest from '../../common/types/messages/PostSaleHistoryRequest';
import { LoadingComponent } from '../../components/LoadingComponent/Loading';
import { ErrorComponent } from '../../components/ErrorComponent/Error';
import { Container, Row, Form, Col, Button, Spinner } from 'react-bootstrap'
import CachedSaleHistoryEntry from '../../common/types/CachedSaleHistoryEntry';
import { postSaleHistory, updateSaleHistoryForm } from '../../store/actions/saleHistoryActions';
import { ApiService } from '../../common/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import WebCity from '../../common/types/WebCity';
import { currencies } from '../../common/enums/currencyEnum';
import { saleHistorySearchTypes } from '../../common/enums/saleHistorySearchTypeEnum';
import { SaleHistoryTable } from '../../components/SaleHistoryTableComponent/SaleHistoryTable';
import './saleHistory.css';
import { MultiSelect } from "react-multi-select-component"
import { LabelValuePair } from '../../common/types/LabelValuePair';
import { CollectionSquare } from '../../components/CollectionSquareComponent/CollectionSquareComponent';

interface StateProps {
  authTokenSet: boolean,

  isLoadingCollections: boolean,
  isLoadingNeighborhoods: boolean,
  isLoadingCities: boolean,
  collectionsLoaded: boolean,
  neighborhoodsLoaded: boolean,
  citiesLoaded: boolean,
  hasLoadingInfoError: boolean,
  infoErrorMessage: string,

  collections: WebCollection[],
  neighborhoods: WebNeighborhood[],
  cities: WebCity[],

  saleHistoryEntries: CachedSaleHistoryEntry[];

  isLoadingSaleHistory: boolean;
  hasErrorSaleHistory: boolean;
  errorSaleHistoryMessage: string;

  saleHistoryFormState: WebSaleHistoryFilters;
  saleHistorySelectedSearchType: number;
};

interface DispatchProps {
  postSaleHistory: (request: PostSaleHistoryRequest) => void;
  updateSaleHistoryForm: (filters: WebSaleHistoryFilters, selectedType: number) => void;
}

export type SaleHistoryProps = DispatchProps & StateProps;

export const SaleHistory = (props: SaleHistoryProps) => {
  
  const context = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.authTokenSet) {
      navigate("/Unauthorized");
    }
  }, [navigate, props.authTokenSet]);

  const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);

  const downloadCSV = async () => {

    setIsDownloadingCSV(true);
    const apiService: ApiService = context.apiService;

    let filename = "";
    
    if (props.saleHistoryFormState.cityIdSearch) {
      filename = `${props.cities.filter((c) => c.cityId === props.saleHistoryFormState.searchByCityId)[0].name.replace(/\s/g, '')}_sale_history.csv`;
    } else {
      filename = `${props.saleHistoryFormState.searchByUsername}_sale_history.csv`; 
    }

    const csvData = (await apiService.postSaleHistory$({filters: props.saleHistoryFormState, asCSV: true}).toPromise()).response.csvString;

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // In case of IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } 

    setIsDownloadingCSV(false);
  }

  const handleEnterKeyPress = (changeEvent: React.KeyboardEvent<HTMLInputElement>) => {
    if (changeEvent.key === 'Enter') {
      searchSaleHistory();
    }
  }

  const onUpdateFilters = (newFilters: WebSaleHistoryFilters) => {
    props.updateSaleHistoryForm(newFilters, props.saleHistorySelectedSearchType);
  }

  const createLabelValueFromCollection = (collection: WebCollection) => {
    return {
      label: <><CollectionSquare key={collection.id} collection={collection} displayRight={false} disableHover={true} /> {collection.name}</>,
      value: "" + collection.id
    } as LabelValuePair
  }

  const createLabelValueFromNeighborhood = (neighborhood: WebNeighborhood) => {
    return {
      label: neighborhood.name,
      value: "" + neighborhood.id
    } as LabelValuePair
  }


  const onNeighborhoodsSelect = (selectedList: LabelValuePair[]) =>{
    props.updateSaleHistoryForm({
      ...props.saleHistoryFormState,
      neighborhoodIds: selectedList.map((c) => parseInt(c.value))
    }, props.saleHistorySelectedSearchType);
  }

  const renderNeighborhoodsSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Neighborhoods <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col xs={7}>
            <MultiSelect
              options={props.neighborhoods.filter(n => n.cityId === props.saleHistoryFormState.searchByCityId).map((b) => createLabelValueFromNeighborhood(b))}
              value={props.neighborhoods.filter(n => props.saleHistoryFormState.neighborhoodIds.includes(n.id)).map((b) => createLabelValueFromNeighborhood(b))}
              onChange={onNeighborhoodsSelect}
              labelledBy="Select"
              className="multi-select"
            />
          </Col>
        </Row>
      </div>
    )
  }

  const customCollectionValueRenderer = (selected: any, _options: any) => {

    if (!selected.length) {
      return "Select...";
    }

    if (selected.length === _options.length) {
      return "All items are selected."
    }

    return selected.map((s: any) => props.collections.filter(c => c.id === parseInt(s.value))[0].name).join(", ");
  };

  const onCollectionSelect = (selectedList: LabelValuePair[]) =>{
    props.updateSaleHistoryForm({
      ...props.saleHistoryFormState,
      collectionIds: selectedList.map((c) => parseInt(c.value))
    }, props.saleHistorySelectedSearchType);
  }

  const renderCollectionsSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Collections <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col xs={7}>
            <MultiSelect
              options={props.collections.filter(c => c.cityId === props.saleHistoryFormState.searchByCityId).map((b) => createLabelValueFromCollection(b))}
              value={props.collections.filter(c => props.saleHistoryFormState.collectionIds.includes(c.id)).map((b) => createLabelValueFromCollection(b))}
              onChange={onCollectionSelect}
              labelledBy="Select"
              className="multi-select"
              valueRenderer={customCollectionValueRenderer}
            />
          </Col>
        </Row>
      </div>
    )
  }

  const renderCurrencySelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Currency <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.saleHistoryFormState.currency}
              onChange={e => {
                onUpdateFilters({
                  ...props.saleHistoryFormState,
                  currency: e.target.value
                });
            }}>
              {currencies.map((c) => <option key={c.key} value={c.value}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderCitySelect = () => {
    if (!props.saleHistoryFormState.cityIdSearch) {
      return (<div></div>);
    } 

    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={6}>
            <h5>City <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.saleHistoryFormState.searchByCityId}
              disabled={props.isLoadingSaleHistory}
              onChange={e => {
                props.updateSaleHistoryForm({
                  ...props.saleHistoryFormState,
                  searchByCityId: parseInt(e.target.value),
                  collectionIds: [],
                  neighborhoodIds: []
                }, props.saleHistorySelectedSearchType);
            }}>
              {props.cities.filter(x => x.isPrimaryCity).sort((aVal, bVal) => aVal.name > bVal.name ? 1 : -1).map((c) => <option key={c.cityId} value={c.cityId}>{c.name}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderSearchTypeSelect = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={6}>
            <h5>Search By <FontAwesomeIcon icon={faCaretDown} /></h5>
          </Col>
          <Col>
            <Form.Control type="dropdown" as="select" value={props.saleHistorySelectedSearchType}
              disabled={props.isLoadingSaleHistory}
              onChange={e => {
                props.updateSaleHistoryForm({
                  ...props.saleHistoryFormState,
                  cityIdSearch: parseInt(e.target.value) === 1,
                  page: 1,
                  collectionIds: [],
                  neighborhoodIds: []
                }, parseInt(e.target.value));
            }}>
              {saleHistorySearchTypes.map((c) => <option key={c.key} value={c.key}>{c.value}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </div>
    )
  }

  const renderAddressBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Address</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.saleHistoryFormState.address}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.saleHistoryFormState,
                  address: e.target.value
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderToggle = (isOn: boolean, offString: string, onString: string, click: any) => {
    if (isOn) {
      return (
        <div className="toggle-on" onClick={click}>
          {onString}
        </div>
      );
    } else {
      return (
        <div className="toggle-off" onClick={click}>
          {offString}
        </div>
      );
    }
  }

  const renderTypesBox = () => {
    return (
      <div className="input-form-column-item toggle-row ">
        <Row>
          {renderToggle(!props.saleHistoryFormState.noSales, "No Sales", "Sales", () => onUpdateFilters({
            ...props.saleHistoryFormState, noSales: !props.saleHistoryFormState.noSales}))}
          {renderToggle(!props.saleHistoryFormState.noSwaps, "No Swaps", "Swaps", () => onUpdateFilters({
            ...props.saleHistoryFormState, noSwaps: !props.saleHistoryFormState.noSwaps}))}
          {renderToggle(!props.saleHistoryFormState.noOffers, "No Offers", "Offers", () => onUpdateFilters({
            ...props.saleHistoryFormState, noOffers: !props.saleHistoryFormState.noOffers}))}
        </Row>
      </div>
    );
  }

  const renderUsernameBox = () => {
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={5}>
            <h5>Username</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.saleHistoryFormState.username}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.saleHistoryFormState,
                  username: e.target.value
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderSearchByUsernameBox = () => {
    if (props.saleHistoryFormState.cityIdSearch) {
      return (<div></div>);
    }

    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={6}>
            <h5>Username</h5>
          </Col>
          <Col>
            <Form.Control type="text" value={props.saleHistoryFormState.searchByUsername}
              disabled={props.isLoadingSaleHistory}
              onKeyPress={handleEnterKeyPress}
              onChange={e => {
                onUpdateFilters({
                  ...props.saleHistoryFormState,
                  searchByUsername: e.target.value
                });
            }}>
            </Form.Control>
          </Col>
        </Row>
      </div>
    );
  }

  const renderDateRangeBox = () => {
    
    return (
      <div className="input-form-column-item">
        <Row>
          <Col xs={2}>
            <h5>Dates</h5>
          </Col>
          <Col xs={5}>
            <div className="sale-history-date-pad-left">
              <DatePicker  
                value={props.saleHistoryFormState.fromDate}
                minDate={new Date(2019, 5, 1)}
                maxDate={props.saleHistoryFormState.toDate}
                onChange={(date: Date) => {
                  onUpdateFilters({
                    ...props.saleHistoryFormState,
                    fromDate: date
                  });
                }}
              />
            </div>
          </Col>
          <Col xs={5}>
            <div className="sale-history-date-pad-right">
              <DatePicker 
                value={props.saleHistoryFormState.toDate}
                maxDate={new Date()}
                onChange={(date: Date) => {
                  onUpdateFilters({
                    ...props.saleHistoryFormState,
                    toDate: date
                  });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }


  const searchSaleHistory = () => {
    props.postSaleHistory({filters: props.saleHistoryFormState, asCSV: false});
  }

  const renderColumnOne = () => {
    if (props.isLoadingCollections || props.isLoadingNeighborhoods || props.isLoadingSaleHistory) {
      return (
      <div>
        <Row><h2>Sale History</h2></Row>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      );
    } else {
      return (
        <div>
          <Row><h2>Sale History</h2></Row>
          <Row>
            <Button variant="dark" onClick={searchSaleHistory} disabled={props.isLoadingSaleHistory}>Search Sale History</Button>
            <Button className="for-sale-download-csv" variant="dark" onClick={downloadCSV} disabled={props.isLoadingSaleHistory || isDownloadingCSV}>Download CSV <FontAwesomeIcon icon={faDownload} /></Button>
          </Row>
        </div>
      );
    }
  }

  const renderColumnTwo = () => {
    return (
      <div>
        {renderSearchTypeSelect()}
        {renderCitySelect()}
        {renderSearchByUsernameBox()}
        {renderTypesBox()}
      </div>
    );
  }

  const renderColumnThree = () => {
    return (
      <div>
        {renderAddressBox()}
        {renderUsernameBox()}
        {renderCurrencySelect()}
      </div>
    );
  }

  const renderColumnFour = () => {
    if (!props.saleHistoryFormState.cityIdSearch) {
      return (<div>{renderDateRangeBox()}</div>);
    }
    return (
      <div>
        {renderDateRangeBox()}
        {renderCollectionsSelect()}
        {renderNeighborhoodsSelect()}
      </div>
    );
  }

  const renderSaleHistoryHeader = () => {
    return (
      <Container fluid={true} className="bg-secondary text-light">
        <Row className="profile-header sale-history-header-min-width">
          <Col className="input-form-column-one input-form-column header-info-column-width-250">
            {renderColumnOne()}
          </Col>
          <Col className="input-form-column header-info-column-width-300">
            {renderColumnTwo()}
          </Col>
          <Col className="input-form-column header-info-column-width-300">
            {renderColumnThree()}
          </Col>
          <Col className="header-info-column-width-450-right">
            {renderColumnFour()}
          </Col>
        </Row>
      </Container>
    );
  }

  const renderSaleHistoryBody = () => {
    return (
      <div className="table-max-height">
        <SaleHistoryTable 
          entries={props.saleHistoryEntries.slice((props.saleHistoryFormState.page - 1) * 100, (props.saleHistoryFormState.page * 100))} 
          neighborhoods={props.neighborhoods} 
          collections={props.collections}
          cities={props.cities}/>
      </div>
    );
  }

  const onPageClick = (direction: number) => {
    if (direction === 0 && props.saleHistoryFormState.page > 1) {
      onUpdateFilters({
        ...props.saleHistoryFormState,
        page: props.saleHistoryFormState.page - 1
      })
    } else if (direction === 1 && props.saleHistoryFormState.page) {
      onUpdateFilters({
        ...props.saleHistoryFormState,
        page: props.saleHistoryFormState.page + 1
      })
    }
  }

  const updatePageNumber = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    let parsedNum = parseInt(changeEvent.currentTarget.value);

    if (isNaN(parsedNum)) {
      parsedNum = 0;
    }
    onUpdateFilters({
      ...props.saleHistoryFormState,
      page: parsedNum
    });
  }

  const renderSaleHistory = () => {
    if (props.isLoadingCollections || props.isLoadingNeighborhoods) {
      return (
        <LoadingComponent />
      );
    } else if (props.hasLoadingInfoError) {
      return (
        <ErrorComponent errorMessage={props.infoErrorMessage} />
      );
    } else {
      return (
        <div>
          {renderSaleHistoryHeader()}
          {renderSaleHistoryBody()}
        </div>
      );
    }
  }

  return (
    <Container fluid={true} className="bg-secondary text-light">
      {renderSaleHistory()}
      <div className="page-selector-class">
        <Row>
          <Button className="page-selector-left" onClick={() => onPageClick(0)}><FontAwesomeIcon icon={faArrowLeft} /></Button>
          <Col className="page-selector-center">
            <Row>
              <Col className="page-selector-center-left"><Form.Label>Page</Form.Label></Col>
              <Col className="page-selector-center-right"><Form.Control size="sm" placeholder="Page" type="text" value={props.saleHistoryFormState.page} onChange={updatePageNumber} /></Col>
            </Row>
          </Col>
          <Button className="page-selector-right" onClick={() => onPageClick(1)}><FontAwesomeIcon icon={faArrowRight} /></Button>
        </Row>
      </div>
    </Container>
  );
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const dispatchProps: DispatchProps = {
    postSaleHistory: (request: PostSaleHistoryRequest) => dispatch(postSaleHistory(request)),
    updateSaleHistoryForm: (filters: WebSaleHistoryFilters, selectedType: number) => dispatch(updateSaleHistoryForm(filters, selectedType))
  }

  return dispatchProps;
}

export const mapStateToProps = (state: AppState) => {
  const stateProps: StateProps = {
    authTokenSet: state.LoginState.authTokenSet,
    isLoadingCollections: state.InfoState.isLoadingCollections,
    isLoadingNeighborhoods: state.InfoState.isLoadingNeighborhoods,
    isLoadingCities: state.InfoState.isLoadingCities,
    collectionsLoaded: state.InfoState.collections.length > 0,
    neighborhoodsLoaded: state.InfoState.neighborhoods.length > 0,
    citiesLoaded: state.InfoState.cities.length > 0,
    hasLoadingInfoError: state.InfoState.hasError,
    infoErrorMessage: state.InfoState.errorMessage,

    collections: state.InfoState.collections,
    neighborhoods: state.InfoState.neighborhoods,
    cities: state.InfoState.cities,

    saleHistoryEntries: state.SaleHistoryState.saleHistoryEntries,

    isLoadingSaleHistory: state.SaleHistoryState.isLoadingSaleHistoryEntries,
    hasErrorSaleHistory: state.SaleHistoryState.hasErrorSaleHistoryEntries,
    errorSaleHistoryMessage: state.SaleHistoryState.errorSaleHistoryEntriesMessage,

    saleHistoryFormState: state.SaleHistoryState.saleHistoryFormState,
    saleHistorySelectedSearchType: state.SaleHistoryState.saleHistorySelectedSearchType,
  }
  return stateProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(SaleHistory);