import React from 'react';
import { Store } from 'redux';
import { Container } from 'react-bootstrap';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Unauthorized from './app/areas/unauthorized/unauthorized';
import Login from './app/areas/login/login';
import Profile from './app/areas/profile/profile';
import UserLookup from './app/areas/userLookup/userLookup';
import Header from './app/areas/header/header';
import Optimizer from './app/areas/optimizer/optimizer';
import Appraisal from './app/areas/appraisal/appraisal';
import ForSaleProperties from './app/areas/forSaleProperties/forSaleProperties';
import UnmintedProperties from './app/areas/unmintedProperties/unmintedProperties';
import Locations from './app/areas/locations/locations';
import SaleHistory from './app/areas/saleHistory/saleHistory';
import Mapping from './app/areas/mapping/mapping';
import Statistics from './app/areas/statistics/statistics';
import SearchNFTs from './app/areas/searchNFTs/searchNFTs';
import Leaderboards from './app/areas/leaderboards/leaderboards';

interface AppProps {
  store: Store
}

export default ({ store }: AppProps) =>  {
  return (
      <Router>
        <Provider store={store}>
          <Header />
          <Container className="top-level-container">
            <Routes>
              <Route
                path="/"
                element={<Login />}
              />
              <Route
                path="/Profile"
                element={<Profile />}
              />
              <Route 
                path="/Unauthorized"
                element={<Unauthorized />}
              />
              <Route 
                path="/Locations"
                element={<Locations />}
              />
              <Route
                path="/Tools/UserLookup"
                element={<UserLookup />}
              />
              <Route
                path="/Tools/Optimizer"
                element={<Optimizer />}
              />
              <Route
                path="/Tools/Appraisal"
                element={<Appraisal />}
              />
              <Route
                path="/Tools/Mapping"
                element={<Mapping />}
              />
              <Route
                path="/Data/ForSaleProperties"
                element={<ForSaleProperties />}
              />
              <Route
                path="/Data/UnmintedProperties"
                element={<UnmintedProperties />}
              />
              <Route
                path="/Data/SaleHistory"
                element={<SaleHistory />}
              />
              <Route
                path="/Data/Statistics"
                element={<Statistics />}
              />
              <Route
                path="/Data/NFTs"
                element={<SearchNFTs />}
              />
              <Route
                path="/Data/Leaderboards"
                element={<Leaderboards />}
              />
            </Routes>
          </Container>
        </Provider> 
      </Router>
  );
};