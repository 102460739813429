import '../../areas/forSaleProperties/forSaleProperties.css';
import { Table, Form, Row } from 'react-bootstrap';
import { UnmintedTableEntry } from './UnmintedTableEntry';
import CachedUnmintedProperty from '../../common/types/CachedUnmintedProperty';
import WebNeighborhood from '../../common/types/WebNeighborhood';
import WebCollection from '../../common/types/WebCollection';

export interface OwnProps {
  properties: CachedUnmintedProperty[],
  neighborhoods: WebNeighborhood[],
  collections: WebCollection[],
}

export const UnmintedPropertyTableComponent = (props: OwnProps) => {
  return (
    <div className="bg-light header-min-width">
      <Table>
        <thead>
          <tr className="form-aligns">
            <th>
              <Row>
                <Form.Label>Address</Form.Label>
              </Row>
            </th>
            <th>
              <Row>
                <Form.Label>Neighborhood</Form.Label>
              </Row>
            </th>
            <th>
              <Row>
                <Form.Label>Size</Form.Label>
              </Row>
            </th>
            <th>
              <Row>
                <Form.Label>FSA</Form.Label>
              </Row>
            </th>
            <th>
              <Row>
                <Form.Label>Mint</Form.Label>
              </Row>
            </th>
            <th>
              <Row>
                <Form.Label>Collections</Form.Label>
              </Row>
            </th>
            <th>
              <Row>
                <Form.Label></Form.Label>
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.properties.map((p) => 
            <UnmintedTableEntry 
              key={p.address + p.cityId} 
              property={p} 
              neighborhood={props.neighborhoods.find(({ id }) => id === p.neighborhoodId)!.name} 
              collections={props.collections.filter((c) => p.collectionIds.includes(c.id))}
            />)}
        </tbody>
      </Table>
    </div>
  );
}
