import CachedSaleHistoryEntry from '../../common/types/CachedSaleHistoryEntry';
import WebCollection from '../../common/types/WebCollection';
import WebCity from '../../common/types/WebCity';
import { useState } from 'react';
import { PropertyInfoComponent } from '../PropertyInfoComponent/PropertyInfoComponent';
import WebNeighborhood from '../../common/types/WebNeighborhood';

export interface OwnProps {
  entry: CachedSaleHistoryEntry,
  propertyCollections: WebCollection[],
  offerPropertyCollections: WebCollection[],
  cities: WebCity[],
  neighborhoods: WebNeighborhood[]
}

export const SaleHistoryTableEntry = (props: OwnProps) => {
  const [displayInfo, setDisplayInfo] = useState(false);
  const [displayOfferInfo, setDisplayOfferInfo] = useState(false);

  const hoverHandler = () => {
    setDisplayInfo(true);
  }

  const outHandler = () => {
    setDisplayInfo(false);
  }

  const hoverOfferHandler = () => {
    setDisplayOfferInfo(true);
  }

  const outOfferHandler = () => {
    setDisplayOfferInfo(false);
  }

  const getNeighborhood = () => {
    let neighborhood = props.neighborhoods.filter((c) => c.id === props.entry.property.neighborhoodId);

    if (neighborhood && neighborhood[0] && neighborhood[0].name) {
      return neighborhood[0].name;
    } else {
      return "";
    }
  }

  return (
    <tr>
      <td>{props.entry.transactionDateTime}</td>
      <td>{props.entry.buyer}</td>
      <td>{props.entry.seller}</td>
      <td>{props.entry.price && props.entry.price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {props.entry.price && props.entry.currency}</td>
      <td>{props.entry.markup && (100 * props.entry.markup).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' %'}</td>
      <td>{props.entry.offer ? "Offer" : ""}</td>
      <td onMouseOver={hoverHandler} onMouseOut={outHandler}>{props.entry.property.address}
        <PropertyInfoComponent 
          city={props.cities.filter((c) => c.cityId === props.entry.property.cityId)[0].name} 
          neighborhood={getNeighborhood()}
          mint={props.entry.property.mint.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} 
          collections={props.propertyCollections} 
          show={displayInfo}/>
      </td>
      <td onMouseOver={hoverOfferHandler} onMouseOut={outOfferHandler}>{props.entry.offerProperty && props.entry.offerProperty.address}
        <PropertyInfoComponent 
          city={props.entry.offerProperty && props.cities.filter((c) => c.cityId === props.entry.offerProperty.cityId)[0].name} 
          neighborhood={getNeighborhood()}
          mint={props.entry.offerProperty && props.entry.offerProperty.mint.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} 
          collections={props.entry.offerProperty && props.offerPropertyCollections} 
          show={props.entry.offerProperty && displayOfferInfo}/>
      </td>
    </tr>
  );
}