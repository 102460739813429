import { Table, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SaleHistoryTableEntry } from './SaleHistoryTableEntry';
import CachedSaleHistoryEntry from '../../common/types/CachedSaleHistoryEntry';
import WebNeighborhood from '../../common/types/WebNeighborhood';
import WebCollection from '../../common/types/WebCollection';
import WebCity from '../../common/types/WebCity';
import '../../areas/forSaleProperties/forSaleProperties.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export interface OwnProps {
  entries: CachedSaleHistoryEntry[],
  neighborhoods: WebNeighborhood[],
  collections: WebCollection[],
  cities: WebCity[]
}

export const SaleHistoryTable = (props: OwnProps) => {
  return (
    <div className="bg-light sale-history-header-min-width">
      <Table>
        <thead>
          <tr className="form-aligns">
            <th>
              <Form.Label>Transaction Date</Form.Label>
            </th>
            <th>
              <Form.Label>Buyer</Form.Label>
            </th>
            <th>
              <Form.Label>Seller</Form.Label>
            </th>
            <th>
              <Form.Label>Price</Form.Label>
            </th>
            <th>
              <Form.Label>Markup</Form.Label>
            </th>
            <th>
             <Form.Label>Offer</Form.Label>
            </th>
            <th>
              <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom`}>
                    Hover over an address for more information
                  </Tooltip>
                }
              >
                <Form.Label>Address <FontAwesomeIcon icon={faInfoCircle} /></Form.Label>
              </OverlayTrigger>
          </th>
            <th>
              <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip id={`tooltip-bottom`}>
                    Hover over an address for more information
                  </Tooltip>
                }
              >
                <Form.Label>Offer Address <FontAwesomeIcon icon={faInfoCircle} /></Form.Label>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.entries.map((e) => 
            <SaleHistoryTableEntry 
              key={e.property.address + e.property.cityId + e.buyer + e.seller + e.price} 
              entry={e} 
              propertyCollections={props.collections.filter((c) => e.property.collectionIds && e.property.collectionIds.includes(c.id))}
              offerPropertyCollections={props.collections.filter((c) => e.offerProperty && e.offerProperty.collectionIds && e.offerProperty.collectionIds.includes(c.id))}
              cities={props.cities}
              neighborhoods={props.neighborhoods}
          />)}
        </tbody>
      </Table>
    </div>
  );
}
